/*
File generated by js-routes 1.4.14
Based on Rails 7.0.4.3 routes of Kasaike::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {"protocol":"https"},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_customer_user_reservations => /customer/users/:user_id/reservations/:reservation_id/accept/:customer_id(.:format)
  // function(user_id, reservation_id, customer_id, options)
  accept_customer_user_reservations_path: Utils.route([["user_id",true],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"accept",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
accept_customer_user_reservations_url: Utils.route([["user_id",true],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"accept",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// accept_in_group_lines_user_bot_shop_reservation_states => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:reservation_id/states/accept_in_group(.:format)
  // function(shop_id, reservation_id, options)
  accept_in_group_lines_user_bot_shop_reservation_states_path: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"accept_in_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
accept_in_group_lines_user_bot_shop_reservation_states_url: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"accept_in_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// accept_in_group_shop_reservation_states => /shops/:shop_id/reservations/:reservation_id/states/accept_in_group(.:format)
  // function(shop_id, reservation_id, options)
  accept_in_group_shop_reservation_states_path: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"accept_in_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
accept_in_group_shop_reservation_states_url: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"accept_in_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// accept_lines_user_bot_customer_reservations => /lines/user_bot/owner(/:business_owner_id)/customer/reservations/:reservation_id/accept/:customer_id(.:format)
  // function(reservation_id, customer_id, options)
  accept_lines_user_bot_customer_reservations_path: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"accept",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
accept_lines_user_bot_customer_reservations_url: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"accept",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// accept_lines_user_bot_shop_reservation_states => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:reservation_id/states/accept(.:format)
  // function(shop_id, reservation_id, options)
  accept_lines_user_bot_shop_reservation_states_path: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
accept_lines_user_bot_shop_reservation_states_url: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// accept_shop_reservation_states => /shops/:shop_id/reservations/:reservation_id/states/accept(.:format)
  // function(shop_id, reservation_id, options)
  accept_shop_reservation_states_path: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
accept_shop_reservation_states_url: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// activate_lines_user_bot_broadcast => /lines/user_bot/owner(/:business_owner_id)/broadcasts/:id/activate(.:format)
  // function(id, options)
  activate_lines_user_bot_broadcast_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
activate_lines_user_bot_broadcast_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// add_customer_lines_user_bot_shop_reservations => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/add_customer(.:format)
  // function(shop_id, options)
  add_customer_lines_user_bot_shop_reservations_path: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"add_customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
add_customer_lines_user_bot_shop_reservations_url: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"add_customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// add_customer_shop_reservations => /shops/:shop_id/reservations/add_customer(.:format)
  // function(shop_id, options)
  add_customer_shop_reservations_path: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"add_customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
add_customer_shop_reservations_url: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"add_customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// admin => /admin(.:format)
  // function(options)
  admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
admin_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// admin_ai_index => /admin/ai(.:format)
  // function(options)
  admin_ai_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_ai_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_as_user => /admin/as_user(.:format)
  // function(options)
  admin_as_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"as_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_as_user_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"as_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_booking_pages => /admin/booking_pages(.:format)
  // function(options)
  admin_booking_pages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_booking_pages_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_business_applications => /admin/business_applications(.:format)
  // function(options)
  admin_business_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"business_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_business_applications_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"business_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_chat => /admin/chats/:id(.:format)
  // function(id, options)
  admin_chat_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"chats",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
admin_chat_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"chats",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// admin_chats => /admin/chats(.:format)
  // function(options)
  admin_chats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"chats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_chats_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"chats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_logs => /admin/logs(.:format)
  // function(options)
  admin_logs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_logs_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_memo => /admin/memo(.:format)
  // function(options)
  admin_memo_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"memo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_memo_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"memo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_online_service_customer_relations => /admin/online_service_customer_relations(.:format)
  // function(options)
  admin_online_service_customer_relations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"online_service_customer_relations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_online_service_customer_relations_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"online_service_customer_relations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_sale_pages => /admin/sale_pages(.:format)
  // function(options)
  admin_sale_pages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sale_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_sale_pages_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sale_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_social_account => /admin/social_account(.:format)
  // function(options)
  admin_social_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"social_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_social_account_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"social_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_subscription => /admin/subscription(.:format)
  // function(options)
  admin_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscription",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_subscription_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscription",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// admin_upgrade_filter_modal_warnings => /warnings/admin_upgrade_filter_modal(.:format)
  // function(options)
  admin_upgrade_filter_modal_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"admin_upgrade_filter_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
admin_upgrade_filter_modal_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"admin_upgrade_filter_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// ahoy_engine => /ahoy
  // function(options)
  ahoy_engine_path: Utils.route([], {}, [2,[7,"/",false],[6,"ahoy",false]]),
ahoy_engine_url: Utils.route([], {}, [2,[7,"/",false],[6,"ahoy",false]], true),
// ahoy_engine.visits => /ahoy/visits(.:format)
  // function(options)
  ahoy_engine_visits_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"ahoy",false]],[7,"/",false]],[2,[6,"visits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
ahoy_engine_visits_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"ahoy",false]],[7,"/",false]],[2,[6,"visits",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// ahoy_engine.events => /ahoy/events(.:format)
  // function(options)
  ahoy_engine_events_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"ahoy",false]],[7,"/",false]],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
ahoy_engine_events_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"ahoy",false]],[7,"/",false]],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// apply_business => /business/apply(.:format)
  // function(options)
  apply_business_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[6,"apply",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
apply_business_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[6,"apply",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// approve_admin_business_application => /admin/business_applications/:id/approve(.:format)
  // function(id, options)
  approve_admin_business_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"business_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
approve_admin_business_application_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"business_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// approve_lines_user_bot_service_customer => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/customers/:id/approve(.:format)
  // function(service_id, id, options)
  approve_lines_user_bot_service_customer_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
approve_lines_user_bot_service_customer_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// ask_confirmation_code_booking_page => /booking_pages/:id/ask_confirmation_code(.:format)
  // function(id, options)
  ask_confirmation_code_booking_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ask_confirmation_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
ask_confirmation_code_booking_page_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ask_confirmation_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// available_options_lines_user_bot_bookings => /lines/user_bot/owner(/:business_owner_id)/bookings/available_options(.:format)
  // function(options)
  available_options_lines_user_bot_bookings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"available_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
available_options_lines_user_bot_bookings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"available_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// bind_settings_user_contact_group => /settings/users/:user_id/contact_groups/:id/bind(.:format)
  // function(user_id, id, options)
  bind_settings_user_contact_group_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bind",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
bind_settings_user_contact_group_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bind",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// blazer => /_blazer
  // function(options)
  blazer_path: Utils.route([], {}, [2,[7,"/",false],[6,"_blazer",false]]),
blazer_url: Utils.route([], {}, [2,[7,"/",false],[6,"_blazer",false]], true),
// blazer.run_queries => /_blazer/queries/run(.:format)
  // function(options)
  blazer_run_queries_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_run_queries_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.cancel_queries => /_blazer/queries/cancel(.:format)
  // function(options)
  blazer_cancel_queries_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_cancel_queries_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.refresh_query => /_blazer/queries/:id/refresh(.:format)
  // function(id, options)
  blazer_refresh_query_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
blazer_refresh_query_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// blazer.tables_queries => /_blazer/queries/tables(.:format)
  // function(options)
  blazer_tables_queries_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_tables_queries_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.schema_queries => /_blazer/queries/schema(.:format)
  // function(options)
  blazer_schema_queries_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"schema",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_schema_queries_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"schema",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.docs_queries => /_blazer/queries/docs(.:format)
  // function(options)
  blazer_docs_queries_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_docs_queries_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.queries => /_blazer/queries(.:format)
  // function(options)
  blazer_queries_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
blazer_queries_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// blazer.new_query => /_blazer/queries/new(.:format)
  // function(options)
  blazer_new_query_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_new_query_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.edit_query => /_blazer/queries/:id/edit(.:format)
  // function(id, options)
  blazer_edit_query_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
blazer_edit_query_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// blazer.query => /_blazer/queries/:id(.:format)
  // function(id, options)
  blazer_query_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_query_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.run_check => /_blazer/checks/:id/run(.:format)
  // function(id, options)
  blazer_run_check_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
blazer_run_check_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// blazer.checks => /_blazer/checks(.:format)
  // function(options)
  blazer_checks_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
blazer_checks_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// blazer.new_check => /_blazer/checks/new(.:format)
  // function(options)
  blazer_new_check_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_new_check_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.edit_check => /_blazer/checks/:id/edit(.:format)
  // function(id, options)
  blazer_edit_check_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
blazer_edit_check_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// blazer.check => /_blazer/checks/:id(.:format)
  // function(id, options)
  blazer_check_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_check_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.refresh_dashboard => /_blazer/dashboards/:id/refresh(.:format)
  // function(id, options)
  blazer_refresh_dashboard_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
blazer_refresh_dashboard_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// blazer.dashboards => /_blazer/dashboards(.:format)
  // function(options)
  blazer_dashboards_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
blazer_dashboards_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// blazer.new_dashboard => /_blazer/dashboards/new(.:format)
  // function(options)
  blazer_new_dashboard_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_new_dashboard_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.edit_dashboard => /_blazer/dashboards/:id/edit(.:format)
  // function(id, options)
  blazer_edit_dashboard_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
blazer_edit_dashboard_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// blazer.dashboard => /_blazer/dashboards/:id(.:format)
  // function(id, options)
  blazer_dashboard_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
blazer_dashboard_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// blazer.root => /_blazer/
  // function(options)
  blazer_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]]),
blazer_root_url: Utils.route([], {}, [2,[2,[7,"/",false],[6,"_blazer",false]],[7,"/",false]], true),
// booking => /bookings/:slug(.:format)
  // function(slug, options)
  booking_path: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
booking_url: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// booking_options_settings_user_booking_pages => /settings/users/:user_id/booking_pages/booking_options(.:format)
  // function(user_id, options)
  booking_options_settings_user_booking_pages_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
booking_options_settings_user_booking_pages_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// booking_page => /booking_pages/:id(.:format)
  // function(id, options)
  booking_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
booking_page_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// booking_page_settings_calendars => /calendars/booking_page_settings(.:format)
  // function(options)
  booking_page_settings_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"booking_page_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
booking_page_settings_calendars_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"booking_page_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// booking_reservation_booking_page => /booking_pages/:id/booking_reservation(.:format)
  // function(id, options)
  booking_reservation_booking_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"booking_reservation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
booking_reservation_booking_page_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"booking_reservation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// booking_times_booking_page => /booking_pages/:id/booking_times(.:format)
  // function(id, options)
  booking_times_booking_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"booking_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
booking_times_booking_page_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"booking_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// build_by_faq_admin_ai_index => /admin/ai/build_by_faq(.:format)
  // function(options)
  build_by_faq_admin_ai_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[2,[7,"/",false],[2,[6,"build_by_faq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
build_by_faq_admin_ai_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[2,[7,"/",false],[2,[6,"build_by_faq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// build_by_url_admin_ai_index => /admin/ai/build_by_url(.:format)
  // function(options)
  build_by_url_admin_ai_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[2,[7,"/",false],[2,[6,"build_by_url",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
build_by_url_admin_ai_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[2,[7,"/",false],[2,[6,"build_by_url",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// business => /business(.:format)
  // function(options)
  business_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
business_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// business_time_settings_user_booking_pages => /settings/users/:user_id/booking_pages/business_time(.:format)
  // function(user_id, options)
  business_time_settings_user_booking_pages_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"business_time",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
business_time_settings_user_booking_pages_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"business_time",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// calendar_booking_page => /booking_pages/:id/calendar(.:format)
  // function(id, options)
  calendar_booking_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
calendar_booking_page_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// callback_modal_lines_user_bot_settings_social_account => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/callback_modal(.:format)
  // function(options)
  callback_modal_lines_user_bot_settings_social_account_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"callback_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
callback_modal_lines_user_bot_settings_social_account_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"callback_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// cancel_customer_user_reservations => /customer/users/:user_id/reservations/:reservation_id/cancel/:customer_id(.:format)
  // function(user_id, reservation_id, customer_id, options)
  cancel_customer_user_reservations_path: Utils.route([["user_id",true],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"cancel",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
cancel_customer_user_reservations_url: Utils.route([["user_id",true],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"cancel",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// cancel_lines_user_bot_customer_reservations => /lines/user_bot/owner(/:business_owner_id)/customer/reservations/:reservation_id/cancel/:customer_id(.:format)
  // function(reservation_id, customer_id, options)
  cancel_lines_user_bot_customer_reservations_path: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"cancel",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
cancel_lines_user_bot_customer_reservations_url: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"cancel",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// cancel_lines_user_bot_service_customer => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/customers/:id/cancel(.:format)
  // function(service_id, id, options)
  cancel_lines_user_bot_service_customer_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
cancel_lines_user_bot_service_customer_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// cancel_lines_user_bot_shop_reservation_states => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:reservation_id/states/cancel(.:format)
  // function(shop_id, reservation_id, options)
  cancel_lines_user_bot_shop_reservation_states_path: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
cancel_lines_user_bot_shop_reservation_states_url: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// cancel_paid_customers_lines_user_bot_warnings => /lines/user_bot/owner(/:business_owner_id)/warnings/cancel_paid_customers/:reservation_id(.:format)
  // function(reservation_id, options)
  cancel_paid_customers_lines_user_bot_warnings_path: Utils.route([["business_owner_id",false],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"cancel_paid_customers",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
cancel_paid_customers_lines_user_bot_warnings_url: Utils.route([["business_owner_id",false],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"cancel_paid_customers",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// cancel_shop_reservation_states => /shops/:shop_id/reservations/:reservation_id/states/cancel(.:format)
  // function(shop_id, reservation_id, options)
  cancel_shop_reservation_states_path: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
cancel_shop_reservation_states_url: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
cancel_user_registration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// change_card_customer_payments => /online_services/:slug/customer_payments/change_card(.:format)
  // function(slug, options)
  change_card_customer_payments_path: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"customer_payments",false],[2,[7,"/",false],[2,[6,"change_card",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
change_card_customer_payments_url: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"customer_payments",false],[2,[7,"/",false],[2,[6,"change_card",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// change_card_lines_user_bot_settings_payments => /lines/user_bot/owner(/:business_owner_id)/settings/payments/change_card(.:format)
  // function(options)
  change_card_lines_user_bot_settings_payments_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"change_card",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
change_card_lines_user_bot_settings_payments_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"change_card",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// change_expire_at_lines_user_bot_service_customer => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/customers/:id/change_expire_at(.:format)
  // function(service_id, id, options)
  change_expire_at_lines_user_bot_service_customer_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_expire_at",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
change_expire_at_lines_user_bot_service_customer_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_expire_at",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// change_verified_line_settings_lines_user_bot_warnings => /lines/user_bot/owner(/:business_owner_id)/warnings/change_verified_line_settings(.:format)
  // function(options)
  change_verified_line_settings_lines_user_bot_warnings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"change_verified_line_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
change_verified_line_settings_lines_user_bot_warnings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"change_verified_line_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// check_in_lines_user_bot_shop_reservation_states => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:reservation_id/states/check_in(.:format)
  // function(shop_id, reservation_id, options)
  check_in_lines_user_bot_shop_reservation_states_path: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"check_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
check_in_lines_user_bot_shop_reservation_states_url: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"check_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// check_in_shop_reservation_states => /shops/:shop_id/reservations/:reservation_id/states/check_in(.:format)
  // function(shop_id, reservation_id, options)
  check_in_shop_reservation_states_path: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"check_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
check_in_shop_reservation_states_url: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"check_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// check_out_lines_user_bot_shop_reservation_states => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:reservation_id/states/check_out(.:format)
  // function(shop_id, reservation_id, options)
  check_out_lines_user_bot_shop_reservation_states_path: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"check_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
check_out_lines_user_bot_shop_reservation_states_url: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"check_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// check_out_shop_reservation_states => /shops/:shop_id/reservations/:reservation_id/states/check_out(.:format)
  // function(shop_id, reservation_id, options)
  check_out_shop_reservation_states_path: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"check_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
check_out_shop_reservation_states_url: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"check_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// check_reservation_content_lines_user_bot_warnings => /lines/user_bot/owner(/:business_owner_id)/warnings/check_reservation_content(.:format)
  // function(options)
  check_reservation_content_lines_user_bot_warnings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"check_reservation_content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
check_reservation_content_lines_user_bot_warnings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"check_reservation_content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// clone_lines_user_bot_broadcast => /lines/user_bot/owner(/:business_owner_id)/broadcasts/:id/clone(.:format)
  // function(id, options)
  clone_lines_user_bot_broadcast_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
clone_lines_user_bot_broadcast_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// clone_lines_user_bot_sale => /lines/user_bot/owner(/:business_owner_id)/sales/:id/clone(.:format)
  // function(id, options)
  clone_lines_user_bot_sale_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
clone_lines_user_bot_sale_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// company_lines_user_bot_settings_profile => /lines/user_bot/owner(/:business_owner_id)/settings/profile/company(.:format)
  // function(options)
  company_lines_user_bot_settings_profile_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"company",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
company_lines_user_bot_settings_profile_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"company",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// confirm_code_booking_page => /booking_pages/:id/confirm_code(.:format)
  // function(id, options)
  confirm_code_booking_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
confirm_code_booking_page_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// connections_settings_user_contact_group => /settings/users/:user_id/contact_groups/:id/connections(.:format)
  // function(user_id, id, options)
  connections_settings_user_contact_group_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
connections_settings_user_contact_group_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// copy_modal_settings_referrers => /settings/referrers/copy_modal(.:format)
  // function(options)
  copy_modal_settings_referrers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"referrers",false],[2,[7,"/",false],[2,[6,"copy_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
copy_modal_settings_referrers_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"referrers",false],[2,[7,"/",false],[2,[6,"copy_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// copy_modal_settings_user_booking_page => /settings/users/:user_id/booking_pages/:id/copy_modal(.:format)
  // function(user_id, id, options)
  copy_modal_settings_user_booking_page_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
copy_modal_settings_user_booking_page_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// correct_admin_ai_index => /admin/ai/correct(.:format)
  // function(options)
  correct_admin_ai_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[2,[7,"/",false],[2,[6,"correct",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
correct_admin_ai_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[2,[7,"/",false],[2,[6,"correct",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// create_admin_custom_messages => /admin/custom_messages/scenario/:scenario(.:format)
  // function(scenario, options)
  create_admin_custom_messages_path: Utils.route([["scenario",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
create_admin_custom_messages_url: Utils.route([["scenario",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// create_application_lines_user_bot_settings_consultants => /lines/user_bot/owner(/:business_owner_id)/settings/consultants/create_application(.:format)
  // function(options)
  create_application_lines_user_bot_settings_consultants_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"consultants",false],[2,[7,"/",false],[2,[6,"create_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
create_application_lines_user_bot_settings_consultants_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"consultants",false],[2,[7,"/",false],[2,[6,"create_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// create_booking_page_lines_user_bot_warnings => /lines/user_bot/owner(/:business_owner_id)/warnings/create_booking_page(.:format)
  // function(options)
  create_booking_page_lines_user_bot_warnings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_booking_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
create_booking_page_lines_user_bot_warnings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_booking_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// create_booking_page_warnings => /warnings/create_booking_page(.:format)
  // function(options)
  create_booking_page_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_booking_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
create_booking_page_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_booking_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// create_course_lines_user_bot_warnings => /lines/user_bot/owner(/:business_owner_id)/warnings/create_course(.:format)
  // function(options)
  create_course_lines_user_bot_warnings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_course",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
create_course_lines_user_bot_warnings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_course",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// create_reservation_lines_user_bot_warnings => /lines/user_bot/owner(/:business_owner_id)/warnings/create_reservation(.:format)
  // function(options)
  create_reservation_lines_user_bot_warnings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_reservation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
create_reservation_lines_user_bot_warnings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_reservation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// create_reservation_warnings => /warnings/create_reservation(.:format)
  // function(options)
  create_reservation_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_reservation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
create_reservation_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"create_reservation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// csv_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/csv(.:format)
  // function(options)
  csv_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
csv_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// current_lines_user_bot_settings_social_account_social_rich_menu => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/social_rich_menus/:id/current(.:format)
  // function(id, options)
  current_lines_user_bot_settings_social_account_social_rich_menu_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
current_lines_user_bot_settings_social_account_social_rich_menu_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// custom_messages_lines_user_bot_settings_shops => /lines/user_bot/owner(/:business_owner_id)/settings/shops/custom_messages(.:format)
  // function(options)
  custom_messages_lines_user_bot_settings_shops_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
custom_messages_lines_user_bot_settings_shops_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// custom_schedule => /custom_schedules/:id(.:format)
  // function(id, options)
  custom_schedule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_schedules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
custom_schedule_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_schedules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// custom_schedules => /custom_schedules(.:format)
  // function(options)
  custom_schedules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
custom_schedules_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// customer_dashboard_for_staff_warnings => /warnings/customer_dashboard_for_staff(.:format)
  // function(options)
  customer_dashboard_for_staff_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"customer_dashboard_for_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
customer_dashboard_for_staff_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"customer_dashboard_for_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// customer_from_callbacks_reminder_permissions => /callbacks/reminder_permissions/:encrypted_data(.:format)
  // function(encrypted_data, options)
  customer_from_callbacks_reminder_permissions_path: Utils.route([["encrypted_data",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"reminder_permissions",false],[2,[7,"/",false],[2,[3,"encrypted_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
customer_from_callbacks_reminder_permissions_url: Utils.route([["encrypted_data",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"reminder_permissions",false],[2,[7,"/",false],[2,[3,"encrypted_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// customer_payments => /online_services/:slug/customer_payments(.:format)
  // function(slug, options)
  customer_payments_path: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"customer_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
customer_payments_url: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"customer_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// customer_status_online_service => /online_services/:slug/customer_status/:encrypted_social_service_user_id(.:format)
  // function(slug, encrypted_social_service_user_id, options)
  customer_status_online_service_path: Utils.route([["slug",true],["encrypted_social_service_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"customer_status",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
customer_status_online_service_url: Utils.route([["slug",true],["encrypted_social_service_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"customer_status",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// customer_user_filter_index => /customer/users/:user_id/filter(.:format)
  // function(user_id, options)
  customer_user_filter_index_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
customer_user_filter_index_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// customer_user_messages => /customer/users/:user_id/messages(.:format)
  // function(user_id, options)
  customer_user_messages_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
customer_user_messages_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// customer_user_printing_index => /customer/users/:user_id/printing(.:format)
  // function(user_id, options)
  customer_user_printing_index_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"printing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
customer_user_printing_index_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"printing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// customer_user_reservations => /customer/users/:user_id/reservations(.:format)
  // function(user_id, options)
  customer_user_reservations_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
customer_user_reservations_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// customer_user_saved_filters => /customer/users/:user_id/saved_filters(.:format)
  // function(user_id, options)
  customer_user_saved_filters_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
customer_user_saved_filters_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// customers_count_lines_user_bot_broadcasts => /lines/user_bot/owner(/:business_owner_id)/broadcasts/customers_count(.:format)
  // function(options)
  customers_count_lines_user_bot_broadcasts_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[6,"customers_count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
customers_count_lines_user_bot_broadcasts_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[6,"customers_count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// data_changed_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/data_changed/:reservation_customer_id(.:format)
  // function(reservation_customer_id, options)
  data_changed_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["reservation_customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"data_changed",false],[2,[7,"/",false],[2,[3,"reservation_customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
data_changed_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["reservation_customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"data_changed",false],[2,[7,"/",false],[2,[3,"reservation_customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// data_changed_user_customers => /users/:user_id/customers/data_changed/:reservation_customer_id(.:format)
  // function(user_id, reservation_customer_id, options)
  data_changed_user_customers_path: Utils.route([["user_id",true],["reservation_customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"data_changed",false],[2,[7,"/",false],[2,[3,"reservation_customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
data_changed_user_customers_url: Utils.route([["user_id",true],["reservation_customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"data_changed",false],[2,[7,"/",false],[2,[3,"reservation_customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// date_lines_user_bot_schedules => /lines/user_bot/owner(/:business_owner_id)/schedules/:reservation_date(/r/:reservation_id)(.:format)
  // function(reservation_date, options)
  date_lines_user_bot_schedules_path: Utils.route([["business_owner_id",false],["reservation_date",true],["reservation_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"reservation_date",false],[2,[1,[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[3,"reservation_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
date_lines_user_bot_schedules_url: Utils.route([["business_owner_id",false],["reservation_date",true],["reservation_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"reservation_date",false],[2,[1,[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[3,"reservation_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// date_member => /member/:reservation_date(/r/:reservation_id)(.:format)
  // function(reservation_date, options)
  date_member_path: Utils.route([["reservation_date",true],["reservation_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"member",false],[2,[7,"/",false],[2,[3,"reservation_date",false],[2,[1,[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[3,"reservation_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
date_member_url: Utils.route([["reservation_date",true],["reservation_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"member",false],[2,[7,"/",false],[2,[3,"reservation_date",false],[2,[1,[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[3,"reservation_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// date_shop_reservations => /shops/:shop_id/reservations/:reservation_date(.:format)
  // function(shop_id, reservation_date, options)
  date_shop_reservations_path: Utils.route([["shop_id",true],["reservation_date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
date_shop_reservations_url: Utils.route([["shop_id",true],["reservation_date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// delayed_job_web => /_delayed_job
  // function(options)
  delayed_job_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"_delayed_job",false]]),
delayed_job_web_url: Utils.route([], {}, [2,[7,"/",false],[6,"_delayed_job",false]], true),
// delayed_web => /_jobs
  // function(options)
  delayed_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"_jobs",false]]),
delayed_web_url: Utils.route([], {}, [2,[7,"/",false],[6,"_jobs",false]], true),
// delayed_web.root => /_jobs/
  // function(options)
  delayed_web_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"_jobs",false]],[7,"/",false]]),
delayed_web_root_url: Utils.route([], {}, [2,[2,[7,"/",false],[6,"_jobs",false]],[7,"/",false]], true),
// delayed_web.queue_job => /_jobs/jobs/:id/queue(.:format)
  // function(id, options)
  delayed_web_queue_job_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_jobs",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"queue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
delayed_web_queue_job_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_jobs",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"queue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// delayed_web.jobs => /_jobs/jobs(.:format)
  // function(options)
  delayed_web_jobs_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_jobs",false]],[7,"/",false]],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
delayed_web_jobs_url: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_jobs",false]],[7,"/",false]],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// delayed_web.job => /_jobs/jobs/:id(.:format)
  // function(id, options)
  delayed_web_job_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_jobs",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
delayed_web_job_url: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_jobs",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// delete_customer_user_saved_filters => /customer/users/:user_id/saved_filters/delete(.:format)
  // function(user_id, options)
  delete_customer_user_saved_filters_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
delete_customer_user_saved_filters_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// delete_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/delete(.:format)
  // function(options)
  delete_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
delete_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// delete_menu_lines_user_bot_booking_option => /lines/user_bot/owner(/:business_owner_id)/booking_options/:id/menus/:menu_id(.:format)
  // function(id, menu_id, options)
  delete_menu_lines_user_bot_booking_option_path: Utils.route([["business_owner_id",false],["id",true],["menu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"menu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
delete_menu_lines_user_bot_booking_option_url: Utils.route([["business_owner_id",false],["id",true],["menu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"menu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// delete_message_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/delete_message(.:format)
  // function(options)
  delete_message_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"delete_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
delete_message_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"delete_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// delete_option_lines_user_bot_booking_page => /lines/user_bot/owner(/:business_owner_id)/booking_pages/:id/booking_options/:booking_option_id(.:format)
  // function(id, booking_option_id, options)
  delete_option_lines_user_bot_booking_page_path: Utils.route([["business_owner_id",false],["id",true],["booking_option_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"booking_option_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
delete_option_lines_user_bot_booking_page_url: Utils.route([["business_owner_id",false],["id",true],["booking_option_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"booking_option_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// delete_reservation_user_saved_filters => /reservation/users/:user_id/saved_filters/delete(.:format)
  // function(user_id, options)
  delete_reservation_user_saved_filters_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reservation",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
delete_reservation_user_saved_filters_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reservation",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// delete_user_customers => /users/:user_id/customers/delete(.:format)
  // function(user_id, options)
  delete_user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
delete_user_customers_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// demo_admin_custom_messages => /admin/custom_messages/scenario/:scenario/demo(.:format)
  // function(scenario, options)
  demo_admin_custom_messages_path: Utils.route([["scenario",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[7,"/",false],[2,[6,"demo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
demo_admin_custom_messages_url: Utils.route([["scenario",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[7,"/",false],[2,[6,"demo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// demo_lines_user_bot_custom_messages => /lines/user_bot/owner(/:business_owner_id)/custom_messages/demo(.:format)
  // function(options)
  demo_lines_user_bot_custom_messages_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"demo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
demo_lines_user_bot_custom_messages_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"demo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
destroy_user_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// detail_user_customers => /users/:user_id/customers/detail(.:format)
  // function(user_id, options)
  detail_user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"detail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
detail_user_customers_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"detail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// details_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/details(.:format)
  // function(options)
  details_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
details_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// downgrade_lines_user_bot_settings_payments => /lines/user_bot/owner(/:business_owner_id)/settings/payments/downgrade(.:format)
  // function(options)
  downgrade_lines_user_bot_settings_payments_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"downgrade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
downgrade_lines_user_bot_settings_payments_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"downgrade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// downgrade_settings_payments => /settings/payments/downgrade(.:format)
  // function(options)
  downgrade_settings_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"downgrade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
downgrade_settings_payments_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"downgrade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// draft_lines_user_bot_broadcast => /lines/user_bot/owner(/:business_owner_id)/broadcasts/:id/draft(.:format)
  // function(id, options)
  draft_lines_user_bot_broadcast_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"draft",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
draft_lines_user_bot_broadcast_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"draft",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_admin_custom_messages => /admin/custom_messages/scenario/:scenario/edit/:id(.:format)
  // function(scenario, id, options)
  edit_admin_custom_messages_path: Utils.route([["scenario",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_admin_custom_messages_url: Utils.route([["scenario",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_admin_social_account => /admin/social_account/edit(.:format)
  // function(options)
  edit_admin_social_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_admin_social_account_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_at_shop_settings_user_staff => /settings/users/:user_id/staffs/:id/shop/:shop_id/edit(.:format)
  // function(user_id, id, shop_id, options)
  edit_at_shop_settings_user_staff_path: Utils.route([["user_id",true],["id",true],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"shop",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
edit_at_shop_settings_user_staff_url: Utils.route([["user_id",true],["id",true],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"shop",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], true),
// edit_booking_options_lines_user_bot_settings_line_keyword => /lines/user_bot/owner(/:business_owner_id)/settings/line_keyword/edit_booking_options(.:format)
  // function(options)
  edit_booking_options_lines_user_bot_settings_line_keyword_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"line_keyword",false],[2,[7,"/",false],[2,[6,"edit_booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_booking_options_lines_user_bot_settings_line_keyword_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"line_keyword",false],[2,[7,"/",false],[2,[6,"edit_booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_booking_options_order_lines_user_bot_booking_page => /lines/user_bot/owner(/:business_owner_id)/booking_pages/:id/edit_booking_options_order(.:format)
  // function(id, options)
  edit_booking_options_order_lines_user_bot_booking_page_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_booking_options_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_booking_options_order_lines_user_bot_booking_page_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_booking_options_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_booking_pages_lines_user_bot_settings_line_keyword => /lines/user_bot/owner(/:business_owner_id)/settings/line_keyword/edit_booking_pages(.:format)
  // function(options)
  edit_booking_pages_lines_user_bot_settings_line_keyword_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"line_keyword",false],[2,[7,"/",false],[2,[6,"edit_booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_booking_pages_lines_user_bot_settings_line_keyword_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"line_keyword",false],[2,[7,"/",false],[2,[6,"edit_booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_lines_user_bot_booking_option => /lines/user_bot/owner(/:business_owner_id)/booking_options/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_booking_option_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_lines_user_bot_booking_option_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_lines_user_bot_booking_page => /lines/user_bot/owner(/:business_owner_id)/booking_pages/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_booking_page_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_lines_user_bot_booking_page_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_lines_user_bot_broadcast => /lines/user_bot/owner(/:business_owner_id)/broadcasts/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_broadcast_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_lines_user_bot_broadcast_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_lines_user_bot_sale => /lines/user_bot/owner(/:business_owner_id)/sales/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_sale_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_lines_user_bot_sale_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_lines_user_bot_service => /lines/user_bot/owner(/:business_owner_id)/services/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_service_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_lines_user_bot_service_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_lines_user_bot_service_chapter => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/:id/edit(.:format)
  // function(service_id, id, options)
  edit_lines_user_bot_service_chapter_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
edit_lines_user_bot_service_chapter_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// edit_lines_user_bot_service_chapter_lesson => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/:chapter_id/lessons/:id/edit(.:format)
  // function(service_id, chapter_id, id, options)
  edit_lines_user_bot_service_chapter_lesson_path: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]]),
edit_lines_user_bot_service_chapter_lesson_url: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]], true),
// edit_lines_user_bot_service_episode => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/episodes/:id/edit(.:format)
  // function(service_id, id, options)
  edit_lines_user_bot_service_episode_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
edit_lines_user_bot_service_episode_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// edit_lines_user_bot_settings_business_schedules => /lines/user_bot/owner(/:business_owner_id)/settings/business_schedules/shop/:shop_id/edit/:wday(.:format)
  // function(shop_id, wday, options)
  edit_lines_user_bot_settings_business_schedules_path: Utils.route([["business_owner_id",false],["shop_id",true],["wday",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"shop",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"wday",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
edit_lines_user_bot_settings_business_schedules_url: Utils.route([["business_owner_id",false],["shop_id",true],["wday",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"shop",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"wday",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// edit_lines_user_bot_settings_menu => /lines/user_bot/owner(/:business_owner_id)/settings/menus/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_settings_menu_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
edit_lines_user_bot_settings_menu_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// edit_lines_user_bot_settings_profile => /lines/user_bot/owner(/:business_owner_id)/settings/profile/edit(.:format)
  // function(options)
  edit_lines_user_bot_settings_profile_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_lines_user_bot_settings_profile_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_lines_user_bot_settings_shop => /lines/user_bot/owner(/:business_owner_id)/settings/shops/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_settings_shop_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
edit_lines_user_bot_settings_shop_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// edit_lines_user_bot_settings_social_account => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/edit(.:format)
  // function(options)
  edit_lines_user_bot_settings_social_account_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
edit_lines_user_bot_settings_social_account_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// edit_lines_user_bot_settings_social_account_rich_menu => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/rich_menu/edit(.:format)
  // function(options)
  edit_lines_user_bot_settings_social_account_rich_menu_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"rich_menu",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
edit_lines_user_bot_settings_social_account_rich_menu_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"rich_menu",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// edit_lines_user_bot_settings_social_account_social_rich_menu => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/social_rich_menus/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_settings_social_account_social_rich_menu_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
edit_lines_user_bot_settings_social_account_social_rich_menu_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// edit_lines_user_bot_settings_staff => /lines/user_bot/owner(/:business_owner_id)/settings/staffs/:id/edit(.:format)
  // function(id, options)
  edit_lines_user_bot_settings_staff_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
edit_lines_user_bot_settings_staff_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_rails_conductor_inbound_email_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_scenario_lines_user_bot_booking_page_custom_messages => /lines/user_bot/owner(/:business_owner_id)/booking_pages/:booking_page_id/custom_messages/:scenario(/:id)(.:format)
  // function(booking_page_id, scenario, options)
  edit_scenario_lines_user_bot_booking_page_custom_messages_path: Utils.route([["business_owner_id",false],["booking_page_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"booking_page_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
edit_scenario_lines_user_bot_booking_page_custom_messages_url: Utils.route([["business_owner_id",false],["booking_page_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"booking_page_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], true),
// edit_scenario_lines_user_bot_service_chapter_lesson_custom_messages => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/:chapter_id/lessons/:lesson_id/custom_messages/:scenario(/:id)(.:format)
  // function(service_id, chapter_id, lesson_id, scenario, options)
  edit_scenario_lines_user_bot_service_chapter_lesson_custom_messages_path: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["lesson_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"lesson_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]]]]]),
edit_scenario_lines_user_bot_service_chapter_lesson_custom_messages_url: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["lesson_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"lesson_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]]]]], true),
// edit_scenario_lines_user_bot_service_custom_messages => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/custom_messages/:scenario(/:id)(.:format)
  // function(service_id, scenario, options)
  edit_scenario_lines_user_bot_service_custom_messages_path: Utils.route([["business_owner_id",false],["service_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
edit_scenario_lines_user_bot_service_custom_messages_url: Utils.route([["business_owner_id",false],["service_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], true),
// edit_scenario_lines_user_bot_service_episode_custom_messages => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/episodes/:episode_id/custom_messages/:scenario(/:id)(.:format)
  // function(service_id, episode_id, scenario, options)
  edit_scenario_lines_user_bot_service_episode_custom_messages_path: Utils.route([["business_owner_id",false],["service_id",true],["episode_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"episode_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]),
edit_scenario_lines_user_bot_service_episode_custom_messages_url: Utils.route([["business_owner_id",false],["service_id",true],["episode_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"episode_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]], true),
// edit_scenario_lines_user_bot_settings_shop_custom_messages => /lines/user_bot/owner(/:business_owner_id)/settings/shops/:shop_id/custom_messages/:scenario(/:id)(.:format)
  // function(shop_id, scenario, options)
  edit_scenario_lines_user_bot_settings_shop_custom_messages_path: Utils.route([["business_owner_id",false],["shop_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
edit_scenario_lines_user_bot_settings_shop_custom_messages_url: Utils.route([["business_owner_id",false],["shop_id",true],["scenario",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]], true),
// edit_settings_profile => /settings/profile/edit(.:format)
  // function(options)
  edit_settings_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_settings_profile_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_settings_user_booking_option => /settings/users/:user_id/booking_options/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_booking_option_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_booking_option_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_booking_page => /settings/users/:user_id/booking_pages/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_booking_page_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_booking_page_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_category => /settings/users/:user_id/categories/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_category_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_category_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_contact_group => /settings/users/:user_id/contact_groups/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_contact_group_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_contact_group_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_menu => /settings/users/:user_id/menus/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_menu_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_menu_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_rank => /settings/users/:user_id/ranks/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_rank_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"ranks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_rank_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"ranks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_reservation_setting => /settings/users/:user_id/reservation_settings/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_reservation_setting_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservation_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_reservation_setting_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservation_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_shop => /settings/users/:user_id/shops/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_shop_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_shop_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_shop_business_schedules => /settings/users/:user_id/shops/:shop_id/business_schedules/edit(.:format)
  // function(user_id, shop_id, options)
  edit_settings_user_shop_business_schedules_path: Utils.route([["user_id",true],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
edit_settings_user_shop_business_schedules_url: Utils.route([["user_id",true],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// edit_settings_user_social_account => /settings/users/:user_id/social_accounts/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_social_account_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"social_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_social_account_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"social_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_settings_user_staff => /settings/users/:user_id/staffs/:id/edit(.:format)
  // function(user_id, id, options)
  edit_settings_user_staff_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
edit_settings_user_staff_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// edit_staff_for_admin_warnings => /warnings/edit_staff_for_admin(.:format)
  // function(options)
  edit_staff_for_admin_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"edit_staff_for_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
edit_staff_for_admin_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"edit_staff_for_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
edit_user_password_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
edit_user_registration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// fetch_customer_user_saved_filters => /customer/users/:user_id/saved_filters/fetch(.:format)
  // function(user_id, options)
  fetch_customer_user_saved_filters_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[2,[7,"/",false],[2,[6,"fetch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
fetch_customer_user_saved_filters_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[2,[7,"/",false],[2,[6,"fetch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// fetch_reservation_user_saved_filters => /reservation/users/:user_id/saved_filters/fetch(.:format)
  // function(user_id, options)
  fetch_reservation_user_saved_filters_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reservation",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[2,[7,"/",false],[2,[6,"fetch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
fetch_reservation_user_saved_filters_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reservation",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[2,[7,"/",false],[2,[6,"fetch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// filter_dashboard_for_staff_warnings => /warnings/filter_dashboard_for_staff(.:format)
  // function(options)
  filter_dashboard_for_staff_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"filter_dashboard_for_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
filter_dashboard_for_staff_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"filter_dashboard_for_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// filter_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/filter(.:format)
  // function(options)
  filter_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
filter_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// filter_user_customers => /users/:user_id/customers/filter(.:format)
  // function(user_id, options)
  filter_user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
filter_user_customers_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// find_customer_booking_page => /booking_pages/:id/find_customer(.:format)
  // function(id, options)
  find_customer_booking_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"find_customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
find_customer_booking_page_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"find_customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// find_duplicate_customers_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/find_duplicate_customers(.:format)
  // function(options)
  find_duplicate_customers_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"find_duplicate_customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
find_duplicate_customers_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"find_duplicate_customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// form_lines_user_bot_shop_reservations => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/form(/:id)(.:format)
  // function(shop_id, options)
  form_lines_user_bot_shop_reservations_path: Utils.route([["business_owner_id",false],["shop_id",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"form",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
form_lines_user_bot_shop_reservations_url: Utils.route([["business_owner_id",false],["shop_id",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"form",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]], true),
// form_shop_reservations => /shops/:shop_id/reservations/form(/:id)(.:format)
  // function(shop_id, options)
  form_shop_reservations_path: Utils.route([["shop_id",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"form",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
form_shop_reservations_url: Utils.route([["shop_id",true],["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"form",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// incorrect_admin_ai_index => /admin/ai/incorrect(.:format)
  // function(options)
  incorrect_admin_ai_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[2,[7,"/",false],[2,[6,"incorrect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
incorrect_admin_ai_index_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ai",false],[2,[7,"/",false],[2,[6,"incorrect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// index_lines_user_bot_settings_business_schedules => /lines/user_bot/owner(/:business_owner_id)/settings/business_schedules/shop/:shop_id(.:format)
  // function(shop_id, options)
  index_lines_user_bot_settings_business_schedules_path: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"shop",false],[2,[7,"/",false],[2,[3,"shop_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
index_lines_user_bot_settings_business_schedules_url: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"shop",false],[2,[7,"/",false],[2,[3,"shop_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// keyword_rich_menu_size_lines_user_bot_settings_social_account_social_rich_menus => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/social_rich_menus/keyword_rich_menu_size(.:format)
  // function(options)
  keyword_rich_menu_size_lines_user_bot_settings_social_account_social_rich_menus_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[6,"keyword_rich_menu_size",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
keyword_rich_menu_size_lines_user_bot_settings_social_account_social_rich_menus_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[6,"keyword_rich_menu_size",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// line_finished_message_admin_social_account => /admin/social_account/line_finished_message(.:format)
  // function(options)
  line_finished_message_admin_social_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"line_finished_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
line_finished_message_admin_social_account_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"line_finished_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// line_settings_verified_lines_user_bot_warnings => /lines/user_bot/owner(/:business_owner_id)/warnings/line_settings_verified(.:format)
  // function(options)
  line_settings_verified_lines_user_bot_warnings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"line_settings_verified",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
line_settings_verified_lines_user_bot_warnings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"line_settings_verified",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_ask_identification_code => /lines/ask_identification_code(.:format)
  // function(options)
  lines_ask_identification_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"ask_identification_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lines_ask_identification_code_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"ask_identification_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lines_contacts => /lines/contacts/social_service_user_id/:encrypted_social_service_user_id(.:format)
  // function(encrypted_social_service_user_id, options)
  lines_contacts_path: Utils.route([["encrypted_social_service_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"social_service_user_id",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
lines_contacts_url: Utils.route([["encrypted_social_service_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"social_service_user_id",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// lines_customer_sign_in => /lines/customer_sign_in(.:format)
  // function(options)
  lines_customer_sign_in_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"customer_sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lines_customer_sign_in_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"customer_sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lines_customers_online_service_purchases => /lines/customers/online_service_purchases(.:format)
  // function(options)
  lines_customers_online_service_purchases_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"online_service_purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_customers_online_service_purchases_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"online_service_purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_identify_code => /lines/identify_code(.:format)
  // function(options)
  lines_identify_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"identify_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lines_identify_code_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"identify_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lines_identify_shop_customer => /lines/identify_shop_customer(/:social_service_user_id)(.:format)
  // function(options)
  lines_identify_shop_customer_path: Utils.route([["social_service_user_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"identify_shop_customer",false],[2,[1,[2,[7,"/",false],[3,"social_service_user_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
lines_identify_shop_customer_url: Utils.route([["social_service_user_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"identify_shop_customer",false],[2,[1,[2,[7,"/",false],[3,"social_service_user_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// lines_liff => /lines/liff(/:liff_path)(.:format)
  // function(options)
  lines_liff_path: Utils.route([["liff_path",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"liff",false],[2,[1,[2,[7,"/",false],[3,"liff_path",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
lines_liff_url: Utils.route([["liff_path",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"liff",false],[2,[1,[2,[7,"/",false],[3,"liff_path",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// lines_make_contact => /lines/make_contact(.:format)
  // function(options)
  lines_make_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"make_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lines_make_contact_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"make_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lines_update_customer_address => /lines/update_customer_address(.:format)
  // function(options)
  lines_update_customer_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"update_customer_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
lines_update_customer_address_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"update_customer_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// lines_user_bot_booking_option => /lines/user_bot/owner(/:business_owner_id)/booking_options/:id(.:format)
  // function(id, options)
  lines_user_bot_booking_option_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_booking_option_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_booking_options => /lines/user_bot/owner(/:business_owner_id)/booking_options(.:format)
  // function(options)
  lines_user_bot_booking_options_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_booking_options_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_booking_page => /lines/user_bot/owner(/:business_owner_id)/booking_pages/:id(.:format)
  // function(id, options)
  lines_user_bot_booking_page_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_booking_page_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_booking_page_custom_messages => /lines/user_bot/owner(/:business_owner_id)/booking_pages/:booking_page_id/custom_messages(.:format)
  // function(booking_page_id, options)
  lines_user_bot_booking_page_custom_messages_path: Utils.route([["business_owner_id",false],["booking_page_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"booking_page_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_booking_page_custom_messages_url: Utils.route([["business_owner_id",false],["booking_page_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"booking_page_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_booking_pages => /lines/user_bot/owner(/:business_owner_id)/booking_pages(.:format)
  // function(options)
  lines_user_bot_booking_pages_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_booking_pages_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_broadcast => /lines/user_bot/owner(/:business_owner_id)/broadcasts/:id(.:format)
  // function(id, options)
  lines_user_bot_broadcast_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_broadcast_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_broadcasts => /lines/user_bot/owner(/:business_owner_id)/broadcasts(.:format)
  // function(options)
  lines_user_bot_broadcasts_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_broadcasts_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_change_log => /lines/user_bot/change_log(.:format)
  // function(options)
  lines_user_bot_change_log_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"change_log",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_change_log_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"change_log",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_check_shop_profile => /lines/user_bot/check_shop_profile(.:format)
  // function(options)
  lines_user_bot_check_shop_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"check_shop_profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_check_shop_profile_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"check_shop_profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_connect_user => /lines/user_bot/connect(/social_service_user_id/:social_service_user_id)(.:format)
  // function(options)
  lines_user_bot_connect_user_path: Utils.route([["social_service_user_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[1,[2,[7,"/",false],[2,[6,"social_service_user_id",false],[2,[7,"/",false],[3,"social_service_user_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
lines_user_bot_connect_user_url: Utils.route([["social_service_user_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[1,[2,[7,"/",false],[2,[6,"social_service_user_id",false],[2,[7,"/",false],[3,"social_service_user_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// lines_user_bot_create_shop_profile => /lines/user_bot/create_shop_profile(.:format)
  // function(options)
  lines_user_bot_create_shop_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"create_shop_profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_create_shop_profile_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"create_shop_profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_create_user => /lines/user_bot/create_user(.:format)
  // function(options)
  lines_user_bot_create_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"create_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_create_user_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"create_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_custom_message => /lines/user_bot/owner(/:business_owner_id)/custom_messages/:id(.:format)
  // function(id, options)
  lines_user_bot_custom_message_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_custom_message_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_custom_messages => /lines/user_bot/owner(/:business_owner_id)/custom_messages(.:format)
  // function(options)
  lines_user_bot_custom_messages_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_custom_messages_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_custom_schedule => /lines/user_bot/owner(/:business_owner_id)/custom_schedules/:id(.:format)
  // function(id, options)
  lines_user_bot_custom_schedule_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_schedules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_custom_schedule_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_schedules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_custom_schedules => /lines/user_bot/owner(/:business_owner_id)/custom_schedules(.:format)
  // function(options)
  lines_user_bot_custom_schedules_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_custom_schedules_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"custom_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_customer_messages => /lines/user_bot/owner(/:business_owner_id)/customer/messages(.:format)
  // function(options)
  lines_user_bot_customer_messages_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_customer_messages_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_customer_payments => /lines/user_bot/owner(/:business_owner_id)/customer/payments(.:format)
  // function(options)
  lines_user_bot_customer_payments_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_customer_payments_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_customer_reservations => /lines/user_bot/owner(/:business_owner_id)/customer/reservations(.:format)
  // function(options)
  lines_user_bot_customer_reservations_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_customer_reservations_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers(.:format)
  // function(options)
  lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_generate_code => /lines/user_bot/generate_code(.:format)
  // function(options)
  lines_user_bot_generate_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"generate_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_generate_code_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"generate_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_identify_code => /lines/user_bot/identify_code(.:format)
  // function(options)
  lines_user_bot_identify_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"identify_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_identify_code_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"identify_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_line_sign_up => /lines/user_bot/line_sign_up(.:format)
  // function(options)
  lines_user_bot_line_sign_up_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"line_sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_line_sign_up_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"line_sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_metrics_dashboard => /lines/user_bot/owner(/:business_owner_id)/metrics(.:format)
  // function(options)
  lines_user_bot_metrics_dashboard_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_metrics_dashboard_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_metrics_online_service => /lines/user_bot/owner(/:business_owner_id)/metrics/online_services/:id(.:format)
  // function(id, options)
  lines_user_bot_metrics_online_service_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_metrics_online_service_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_metrics_online_services => /lines/user_bot/owner(/:business_owner_id)/metrics/online_services(.:format)
  // function(options)
  lines_user_bot_metrics_online_services_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"online_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_metrics_online_services_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"online_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_metrics_online_services_sale_pages_conversions => /lines/user_bot/owner(/:business_owner_id)/metrics/online_services/:id/sale_pages_conversions(.:format)
  // function(id, options)
  lines_user_bot_metrics_online_services_sale_pages_conversions_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sale_pages_conversions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
lines_user_bot_metrics_online_services_sale_pages_conversions_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sale_pages_conversions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// lines_user_bot_metrics_online_services_sale_pages_visits => /lines/user_bot/owner(/:business_owner_id)/metrics/online_services/:id/sale_pages_visits(.:format)
  // function(id, options)
  lines_user_bot_metrics_online_services_sale_pages_visits_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sale_pages_visits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
lines_user_bot_metrics_online_services_sale_pages_visits_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sale_pages_visits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// lines_user_bot_metrics_sale_pages => /lines/user_bot/owner(/:business_owner_id)/metrics/sale_pages(.:format)
  // function(options)
  lines_user_bot_metrics_sale_pages_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"sale_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_metrics_sale_pages_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"sale_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_metrics_sale_pages_conversions => /lines/user_bot/owner(/:business_owner_id)/metrics/sale_pages/conversions(.:format)
  // function(options)
  lines_user_bot_metrics_sale_pages_conversions_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"sale_pages",false],[2,[7,"/",false],[2,[6,"conversions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_metrics_sale_pages_conversions_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"sale_pages",false],[2,[7,"/",false],[2,[6,"conversions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_metrics_sale_pages_visits => /lines/user_bot/owner(/:business_owner_id)/metrics/sale_pages/visits(.:format)
  // function(options)
  lines_user_bot_metrics_sale_pages_visits_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"sale_pages",false],[2,[7,"/",false],[2,[6,"visits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_metrics_sale_pages_visits_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"sale_pages",false],[2,[7,"/",false],[2,[6,"visits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_notifications => /lines/user_bot/notifications(.:format)
  // function(options)
  lines_user_bot_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_notifications_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_online_service_customer_relation => /lines/user_bot/owner(/:business_owner_id)/online_service_customer_relations/:id(.:format)
  // function(id, options)
  lines_user_bot_online_service_customer_relation_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"online_service_customer_relations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_online_service_customer_relation_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"online_service_customer_relations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_sale => /lines/user_bot/owner(/:business_owner_id)/sales/:id(.:format)
  // function(id, options)
  lines_user_bot_sale_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_sale_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_sales => /lines/user_bot/owner(/:business_owner_id)/sales(.:format)
  // function(options)
  lines_user_bot_sales_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_sales_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_sales_booking_pages => /lines/user_bot/owner(/:business_owner_id)/sales/booking_pages(.:format)
  // function(options)
  lines_user_bot_sales_booking_pages_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_sales_booking_pages_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_sales_online_services => /lines/user_bot/owner(/:business_owner_id)/sales/online_services(.:format)
  // function(options)
  lines_user_bot_sales_online_services_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"online_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_sales_online_services_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"online_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_schedules => /lines/user_bot/owner(/:business_owner_id)/schedules(.:format)
  // function(options)
  lines_user_bot_schedules_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_schedules_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_service => /lines/user_bot/owner(/:business_owner_id)/services/:id(.:format)
  // function(id, options)
  lines_user_bot_service_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_service_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_service_chapter => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/:id(.:format)
  // function(service_id, id, options)
  lines_user_bot_service_chapter_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
lines_user_bot_service_chapter_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// lines_user_bot_service_chapter_lesson => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/:chapter_id/lessons/:id(.:format)
  // function(service_id, chapter_id, id, options)
  lines_user_bot_service_chapter_lesson_path: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
lines_user_bot_service_chapter_lesson_url: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// lines_user_bot_service_chapter_lesson_custom_messages => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/:chapter_id/lessons/:lesson_id/custom_messages(.:format)
  // function(service_id, chapter_id, lesson_id, options)
  lines_user_bot_service_chapter_lesson_custom_messages_path: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["lesson_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"lesson_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]]),
lines_user_bot_service_chapter_lesson_custom_messages_url: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["lesson_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"lesson_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]], true),
// lines_user_bot_service_chapter_lessons => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/:chapter_id/lessons(.:format)
  // function(service_id, chapter_id, options)
  lines_user_bot_service_chapter_lessons_path: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
lines_user_bot_service_chapter_lessons_url: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// lines_user_bot_service_chapters => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters(.:format)
  // function(service_id, options)
  lines_user_bot_service_chapters_path: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_service_chapters_url: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_service_custom_messages => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/custom_messages(.:format)
  // function(service_id, options)
  lines_user_bot_service_custom_messages_path: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_service_custom_messages_url: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_service_customer => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/customers/:id(.:format)
  // function(service_id, id, options)
  lines_user_bot_service_customer_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
lines_user_bot_service_customer_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// lines_user_bot_service_customers => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/customers(.:format)
  // function(service_id, options)
  lines_user_bot_service_customers_path: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_service_customers_url: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_service_episode => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/episodes/:id(.:format)
  // function(service_id, id, options)
  lines_user_bot_service_episode_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
lines_user_bot_service_episode_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// lines_user_bot_service_episode_custom_messages => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/episodes/:episode_id/custom_messages(.:format)
  // function(service_id, episode_id, options)
  lines_user_bot_service_episode_custom_messages_path: Utils.route([["business_owner_id",false],["service_id",true],["episode_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"episode_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
lines_user_bot_service_episode_custom_messages_url: Utils.route([["business_owner_id",false],["service_id",true],["episode_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"episode_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// lines_user_bot_service_episodes => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/episodes(.:format)
  // function(service_id, options)
  lines_user_bot_service_episodes_path: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_service_episodes_url: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_service_lesson => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/lessons/:id(.:format)
  // function(service_id, id, options)
  lines_user_bot_service_lesson_path: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
lines_user_bot_service_lesson_url: Utils.route([["business_owner_id",false],["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// lines_user_bot_services => /lines/user_bot/owner(/:business_owner_id)/services(.:format)
  // function(options)
  lines_user_bot_services_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_services_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_settings => /lines/user_bot/owner(/:business_owner_id)/settings(.:format)
  // function(options)
  lines_user_bot_settings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
lines_user_bot_settings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]], true),
// lines_user_bot_settings_consultants => /lines/user_bot/owner(/:business_owner_id)/settings/consultants(.:format)
  // function(options)
  lines_user_bot_settings_consultants_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"consultants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_consultants_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"consultants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_menu => /lines/user_bot/owner(/:business_owner_id)/settings/menus/:id(.:format)
  // function(id, options)
  lines_user_bot_settings_menu_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_settings_menu_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_settings_menus => /lines/user_bot/owner(/:business_owner_id)/settings/menus(.:format)
  // function(options)
  lines_user_bot_settings_menus_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_menus_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_payments => /lines/user_bot/owner(/:business_owner_id)/settings/payments(.:format)
  // function(options)
  lines_user_bot_settings_payments_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_payments_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_plans => /lines/user_bot/owner(/:business_owner_id)/settings/plans(.:format)
  // function(options)
  lines_user_bot_settings_plans_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_plans_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_profile => /lines/user_bot/owner(/:business_owner_id)/settings/profile(.:format)
  // function(options)
  lines_user_bot_settings_profile_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_profile_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_shop => /lines/user_bot/owner(/:business_owner_id)/settings/shops/:id(.:format)
  // function(id, options)
  lines_user_bot_settings_shop_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_settings_shop_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_settings_shop_custom_messages => /lines/user_bot/owner(/:business_owner_id)/settings/shops/:shop_id/custom_messages(.:format)
  // function(shop_id, options)
  lines_user_bot_settings_shop_custom_messages_path: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
lines_user_bot_settings_shop_custom_messages_url: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// lines_user_bot_settings_shops => /lines/user_bot/owner(/:business_owner_id)/settings/shops(.:format)
  // function(options)
  lines_user_bot_settings_shops_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_shops_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"shops",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_social_account => /lines/user_bot/owner(/:business_owner_id)/settings/social_account(.:format)
  // function(options)
  lines_user_bot_settings_social_account_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_social_account_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_social_account_rich_menu => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/rich_menu(.:format)
  // function(options)
  lines_user_bot_settings_social_account_rich_menu_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"rich_menu",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_settings_social_account_rich_menu_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"rich_menu",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_settings_social_account_social_rich_menus => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/social_rich_menus(.:format)
  // function(options)
  lines_user_bot_settings_social_account_social_rich_menus_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_settings_social_account_social_rich_menus_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_settings_square => /lines/user_bot/owner(/:business_owner_id)/settings/square(.:format)
  // function(options)
  lines_user_bot_settings_square_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"square",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_square_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"square",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_staff => /lines/user_bot/owner(/:business_owner_id)/settings/staffs/:id(.:format)
  // function(id, options)
  lines_user_bot_settings_staff_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_settings_staff_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_settings_staffs => /lines/user_bot/owner(/:business_owner_id)/settings/staffs(.:format)
  // function(options)
  lines_user_bot_settings_staffs_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_staffs_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_settings_stripe => /lines/user_bot/owner(/:business_owner_id)/settings/stripe(.:format)
  // function(options)
  lines_user_bot_settings_stripe_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_settings_stripe_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_shop_reservation => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:id(.:format)
  // function(shop_id, id, options)
  lines_user_bot_shop_reservation_path: Utils.route([["business_owner_id",false],["shop_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
lines_user_bot_shop_reservation_url: Utils.route([["business_owner_id",false],["shop_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// lines_user_bot_shop_reservation_messages => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:reservation_id/messages(.:format)
  // function(shop_id, reservation_id, options)
  lines_user_bot_shop_reservation_messages_path: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
lines_user_bot_shop_reservation_messages_url: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// lines_user_bot_shop_reservations => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations(.:format)
  // function(shop_id, options)
  lines_user_bot_shop_reservations_path: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
lines_user_bot_shop_reservations_url: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// lines_user_bot_sign_up => /lines/user_bot/sign_up(/social_service_user_id/:social_service_user_id)(.:format)
  // function(options)
  lines_user_bot_sign_up_path: Utils.route([["social_service_user_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"sign_up",false],[2,[1,[2,[7,"/",false],[2,[6,"social_service_user_id",false],[2,[7,"/",false],[3,"social_service_user_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
lines_user_bot_sign_up_url: Utils.route([["social_service_user_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"sign_up",false],[2,[1,[2,[7,"/",false],[2,[6,"social_service_user_id",false],[2,[7,"/",false],[3,"social_service_user_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// lines_user_bot_social_user_messages => /lines/user_bot/social_user_messages(.:format)
  // function(options)
  lines_user_bot_social_user_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"social_user_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_user_bot_social_user_messages_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"social_user_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_user_bot_tours_line_settings_required_for_booking_page => /lines/user_bot/owner(/:business_owner_id)/tours/line_settings_required_for_booking_page(.:format)
  // function(options)
  lines_user_bot_tours_line_settings_required_for_booking_page_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"line_settings_required_for_booking_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_tours_line_settings_required_for_booking_page_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"line_settings_required_for_booking_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_user_bot_tours_line_settings_required_for_online_service => /lines/user_bot/owner(/:business_owner_id)/tours/line_settings_required_for_online_service(.:format)
  // function(options)
  lines_user_bot_tours_line_settings_required_for_online_service_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"line_settings_required_for_online_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
lines_user_bot_tours_line_settings_required_for_online_service_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"line_settings_required_for_online_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// lines_verification => /lines/verification/:encrypted_social_service_user_id(.:format)
  // function(encrypted_social_service_user_id, options)
  lines_verification_path: Utils.route([["encrypted_social_service_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"verification",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
lines_verification_url: Utils.route([["encrypted_social_service_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"verification",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// lines_verification_message_api_status => /lines/verification/message_api_status/:encrypted_social_service_user_id(.:format)
  // function(encrypted_social_service_user_id, options)
  lines_verification_message_api_status_path: Utils.route([["encrypted_social_service_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"verification",false],[2,[7,"/",false],[2,[6,"message_api_status",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
lines_verification_message_api_status_url: Utils.route([["encrypted_social_service_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"verification",false],[2,[7,"/",false],[2,[6,"message_api_status",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// login_api_lines_user_bot_settings_social_account => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/login_api(.:format)
  // function(options)
  login_api_lines_user_bot_settings_social_account_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"login_api",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
login_api_lines_user_bot_settings_social_account_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"login_api",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// mark_paid_admin_withdrawal => /admin/withdrawals/:id/mark_paid(.:format)
  // function(id, options)
  mark_paid_admin_withdrawal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"withdrawals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_paid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
mark_paid_admin_withdrawal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"withdrawals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_paid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// member => /member(.:format)
  // function(options)
  member_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"member",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
member_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"member",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// menus_shop_reservations_available_options => /shops/:shop_id/reservations/available_options/menus(.:format)
  // function(shop_id, options)
  menus_shop_reservations_available_options_path: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"available_options",false],[2,[7,"/",false],[2,[6,"menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
menus_shop_reservations_available_options_url: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"available_options",false],[2,[7,"/",false],[2,[6,"menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// message_api_lines_user_bot_settings_social_account => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/message_api(.:format)
  // function(options)
  message_api_lines_user_bot_settings_social_account_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"message_api",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
message_api_lines_user_bot_settings_social_account_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"message_api",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// mine_lines_user_bot_schedules => /lines/user_bot/schedules/mine(.:format)
  // function(options)
  mine_lines_user_bot_schedules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[6,"mine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
mine_lines_user_bot_schedules_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[6,"mine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// my_date_lines_user_bot_schedules => /lines/user_bot/schedules/mine/:reservation_date(/r/:reservation_id)(.:format)
  // function(reservation_date, options)
  my_date_lines_user_bot_schedules_path: Utils.route([["reservation_date",true],["reservation_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[6,"mine",false],[2,[7,"/",false],[2,[3,"reservation_date",false],[2,[1,[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[3,"reservation_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
my_date_lines_user_bot_schedules_url: Utils.route([["reservation_date",true],["reservation_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[6,"mine",false],[2,[7,"/",false],[2,[3,"reservation_date",false],[2,[1,[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[3,"reservation_id",false]]]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// my_working_schedule_lines_user_bot_calendars => /lines/user_bot/calendars/my_working_schedule(.:format)
  // function(options)
  my_working_schedule_lines_user_bot_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"my_working_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
my_working_schedule_lines_user_bot_calendars_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"my_working_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_admin_custom_messages => /admin/custom_messages/scenario/:scenario/new(.:format)
  // function(scenario, options)
  new_admin_custom_messages_path: Utils.route([["scenario",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_admin_custom_messages_url: Utils.route([["scenario",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_application_lines_user_bot_settings_consultants => /lines/user_bot/owner(/:business_owner_id)/settings/consultants/new_application(.:format)
  // function(options)
  new_application_lines_user_bot_settings_consultants_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"consultants",false],[2,[7,"/",false],[2,[6,"new_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
new_application_lines_user_bot_settings_consultants_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"consultants",false],[2,[7,"/",false],[2,[6,"new_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// new_customer_payments => /online_services/:slug/customer_payments/:encrypted_social_service_user_id/new(/:order_id)(.:format)
  // function(slug, encrypted_social_service_user_id, options)
  new_customer_payments_path: Utils.route([["slug",true],["encrypted_social_service_user_id",true],["order_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"customer_payments",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[2,[7,"/",false],[2,[6,"new",false],[2,[1,[2,[7,"/",false],[3,"order_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
new_customer_payments_url: Utils.route([["slug",true],["encrypted_social_service_user_id",true],["order_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"customer_payments",false],[2,[7,"/",false],[2,[3,"encrypted_social_service_user_id",false],[2,[7,"/",false],[2,[6,"new",false],[2,[1,[2,[7,"/",false],[3,"order_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// new_customer_user_printing => /customer/users/:user_id/printing/new(.:format)
  // function(user_id, options)
  new_customer_user_printing_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"printing",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_customer_user_printing_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"printing",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_lines_customers_online_service_purchases => /lines/customers/online_service_purchases/:slug/new(.:format)
  // function(slug, options)
  new_lines_customers_online_service_purchases_path: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"online_service_purchases",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_lines_customers_online_service_purchases_url: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"online_service_purchases",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_lines_user_bot_booking => /lines/user_bot/owner(/:business_owner_id)/bookings/new(.:format)
  // function(options)
  new_lines_user_bot_booking_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
new_lines_user_bot_booking_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// new_lines_user_bot_booking_option => /lines/user_bot/owner(/:business_owner_id)/booking_options/new(.:format)
  // function(options)
  new_lines_user_bot_booking_option_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
new_lines_user_bot_booking_option_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// new_lines_user_bot_booking_page => /lines/user_bot/owner(/:business_owner_id)/booking_pages/new(.:format)
  // function(options)
  new_lines_user_bot_booking_page_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
new_lines_user_bot_booking_page_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// new_lines_user_bot_broadcast => /lines/user_bot/owner(/:business_owner_id)/broadcasts/new(.:format)
  // function(options)
  new_lines_user_bot_broadcast_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
new_lines_user_bot_broadcast_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"broadcasts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// new_lines_user_bot_sale => /lines/user_bot/owner(/:business_owner_id)/sales/new(.:format)
  // function(options)
  new_lines_user_bot_sale_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
new_lines_user_bot_sale_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// new_lines_user_bot_sales_booking_page => /lines/user_bot/owner(/:business_owner_id)/sales/booking_pages/new(.:format)
  // function(options)
  new_lines_user_bot_sales_booking_page_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
new_lines_user_bot_sales_booking_page_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// new_lines_user_bot_sales_online_service => /lines/user_bot/owner(/:business_owner_id)/sales/online_services/new(.:format)
  // function(options)
  new_lines_user_bot_sales_online_service_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
new_lines_user_bot_sales_online_service_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// new_lines_user_bot_service => /lines/user_bot/owner(/:business_owner_id)/services/new(.:format)
  // function(options)
  new_lines_user_bot_service_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
new_lines_user_bot_service_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// new_lines_user_bot_service_chapter => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/new(.:format)
  // function(service_id, options)
  new_lines_user_bot_service_chapter_path: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
new_lines_user_bot_service_chapter_url: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// new_lines_user_bot_service_chapter_lesson => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/:chapter_id/lessons/new(.:format)
  // function(service_id, chapter_id, options)
  new_lines_user_bot_service_chapter_lesson_path: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
new_lines_user_bot_service_chapter_lesson_url: Utils.route([["business_owner_id",false],["service_id",true],["chapter_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[3,"chapter_id",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// new_lines_user_bot_service_episode => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/episodes/new(.:format)
  // function(service_id, options)
  new_lines_user_bot_service_episode_path: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
new_lines_user_bot_service_episode_url: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// new_lines_user_bot_settings_consultant => /lines/user_bot/owner(/:business_owner_id)/settings/consultants/new(.:format)
  // function(options)
  new_lines_user_bot_settings_consultant_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"consultants",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
new_lines_user_bot_settings_consultant_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"consultants",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// new_lines_user_bot_settings_menu => /lines/user_bot/owner(/:business_owner_id)/settings/menus/new(.:format)
  // function(options)
  new_lines_user_bot_settings_menu_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
new_lines_user_bot_settings_menu_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// new_lines_user_bot_settings_social_account => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/new(.:format)
  // function(options)
  new_lines_user_bot_settings_social_account_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
new_lines_user_bot_settings_social_account_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// new_lines_user_bot_settings_social_account_social_rich_menu => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/social_rich_menus/new(.:format)
  // function(options)
  new_lines_user_bot_settings_social_account_social_rich_menu_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
new_lines_user_bot_settings_social_account_social_rich_menu_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// new_lines_user_bot_settings_staff => /lines/user_bot/owner(/:business_owner_id)/settings/staffs/new(.:format)
  // function(options)
  new_lines_user_bot_settings_staff_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
new_lines_user_bot_settings_staff_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// new_lines_user_bot_shop_reservation_messages => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:reservation_id/messages/new(.:format)
  // function(shop_id, reservation_id, options)
  new_lines_user_bot_shop_reservation_messages_path: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
new_lines_user_bot_shop_reservation_messages_url: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// new_lines_user_bot_social_user_message => /lines/user_bot/social_user_messages/new(.:format)
  // function(options)
  new_lines_user_bot_social_user_message_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"social_user_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
new_lines_user_bot_social_user_message_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"social_user_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// new_profile => /profile/new(.:format)
  // function(options)
  new_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_profile_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_rails_conductor_inbound_email_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
new_rails_conductor_inbound_email_source_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// new_settings_user_booking_option => /settings/users/:user_id/booking_options/new(.:format)
  // function(user_id, options)
  new_settings_user_booking_option_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_booking_option_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_booking_page => /settings/users/:user_id/booking_pages/new(.:format)
  // function(user_id, options)
  new_settings_user_booking_page_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_booking_page_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_category => /settings/users/:user_id/categories/new(.:format)
  // function(user_id, options)
  new_settings_user_category_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_category_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_contact_group => /settings/users/:user_id/contact_groups/new(.:format)
  // function(user_id, options)
  new_settings_user_contact_group_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_contact_group_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_menu => /settings/users/:user_id/menus/new(.:format)
  // function(user_id, options)
  new_settings_user_menu_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_menu_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_rank => /settings/users/:user_id/ranks/new(.:format)
  // function(user_id, options)
  new_settings_user_rank_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"ranks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_rank_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"ranks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_reservation_setting => /settings/users/:user_id/reservation_settings/new(.:format)
  // function(user_id, options)
  new_settings_user_reservation_setting_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservation_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_reservation_setting_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservation_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_shop => /settings/users/:user_id/shops/new(.:format)
  // function(user_id, options)
  new_settings_user_shop_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_shop_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_social_account => /settings/users/:user_id/social_accounts/new(.:format)
  // function(user_id, options)
  new_settings_user_social_account_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"social_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_social_account_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"social_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_settings_user_staff => /settings/users/:user_id/staffs/new(.:format)
  // function(user_id, options)
  new_settings_user_staff_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
new_settings_user_staff_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// new_staff_for_admin_warnings => /warnings/new_staff_for_admin(.:format)
  // function(options)
  new_staff_for_admin_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"new_staff_for_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_staff_for_admin_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"new_staff_for_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_user_confirmation_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_user_password_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_user_registration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
new_user_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// new_user_unlock => /users/unlock/new(.:format)
  // function(options)
  new_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
new_user_unlock_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// online_service => /online_services/:slug(.:format)
  // function(slug, options)
  online_service_path: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
online_service_url: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// page_lines_user_bot_bookings => /lines/user_bot/owner(/:business_owner_id)/bookings/page(.:format)
  // function(options)
  page_lines_user_bot_bookings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
page_lines_user_bot_bookings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// pay_business => /business/pay(.:format)
  // function(options)
  pay_business_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[6,"pay",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
pay_business_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[6,"pay",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// pend_customer_user_reservations => /customer/users/:user_id/reservations/:reservation_id/pend/:customer_id(.:format)
  // function(user_id, reservation_id, customer_id, options)
  pend_customer_user_reservations_path: Utils.route([["user_id",true],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"pend",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
pend_customer_user_reservations_url: Utils.route([["user_id",true],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"pend",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// pend_lines_user_bot_customer_reservations => /lines/user_bot/owner(/:business_owner_id)/customer/reservations/:reservation_id/pend/:customer_id(.:format)
  // function(reservation_id, customer_id, options)
  pend_lines_user_bot_customer_reservations_path: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"pend",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
pend_lines_user_bot_customer_reservations_url: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"pend",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// pend_lines_user_bot_shop_reservation_states => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/:reservation_id/states/pend(.:format)
  // function(shop_id, reservation_id, options)
  pend_lines_user_bot_shop_reservation_states_path: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"pend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
pend_lines_user_bot_shop_reservation_states_url: Utils.route([["business_owner_id",false],["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"pend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// pend_shop_reservation_states => /shops/:shop_id/reservations/:reservation_id/states/pend(.:format)
  // function(shop_id, reservation_id, options)
  pend_shop_reservation_states_path: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"pend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
pend_shop_reservation_states_url: Utils.route([["shop_id",true],["reservation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"pend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// personal_working_schedule_calendars => /calendars/personal_working_schedule(.:format)
  // function(options)
  personal_working_schedule_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"personal_working_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
personal_working_schedule_calendars_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"personal_working_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// personal_working_schedule_lines_user_bot_calendars => /lines/user_bot/owner(/:business_owner_id)/calendars/personal_working_schedule(.:format)
  // function(options)
  personal_working_schedule_lines_user_bot_calendars_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"personal_working_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
personal_working_schedule_lines_user_bot_calendars_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"personal_working_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// pg_hero => /_pghero
  // function(options)
  pg_hero_path: Utils.route([], {}, [2,[7,"/",false],[6,"_pghero",false]]),
pg_hero_url: Utils.route([], {}, [2,[7,"/",false],[6,"_pghero",false]], true),
// pg_hero.space => /_pghero(/:database)/space(.:format)
  // function(options)
  pg_hero_space_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_space_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.relation_space => /_pghero(/:database)/space/:relation(.:format)
  // function(relation, options)
  pg_hero_relation_space_path: Utils.route([["database",false],["relation",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[2,[7,"/",false],[2,[3,"relation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
pg_hero_relation_space_url: Utils.route([["database",false],["relation",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[2,[7,"/",false],[2,[3,"relation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// pg_hero.index_bloat => /_pghero(/:database)/index_bloat(.:format)
  // function(options)
  pg_hero_index_bloat_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"index_bloat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_index_bloat_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"index_bloat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.live_queries => /_pghero(/:database)/live_queries(.:format)
  // function(options)
  pg_hero_live_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"live_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_live_queries_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"live_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.queries => /_pghero(/:database)/queries(.:format)
  // function(options)
  pg_hero_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_queries_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.show_query => /_pghero(/:database)/queries/:query_hash(.:format)
  // function(query_hash, options)
  pg_hero_show_query_path: Utils.route([["database",false],["query_hash",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"query_hash",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
pg_hero_show_query_url: Utils.route([["database",false],["query_hash",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"query_hash",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]], true),
// pg_hero.system => /_pghero(/:database)/system(.:format)
  // function(options)
  pg_hero_system_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_system_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.cpu_usage => /_pghero(/:database)/cpu_usage(.:format)
  // function(options)
  pg_hero_cpu_usage_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"cpu_usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_cpu_usage_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"cpu_usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.connection_stats => /_pghero(/:database)/connection_stats(.:format)
  // function(options)
  pg_hero_connection_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connection_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_connection_stats_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connection_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.replication_lag_stats => /_pghero(/:database)/replication_lag_stats(.:format)
  // function(options)
  pg_hero_replication_lag_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"replication_lag_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_replication_lag_stats_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"replication_lag_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.load_stats => /_pghero(/:database)/load_stats(.:format)
  // function(options)
  pg_hero_load_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"load_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_load_stats_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"load_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.free_space_stats => /_pghero(/:database)/free_space_stats(.:format)
  // function(options)
  pg_hero_free_space_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"free_space_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_free_space_stats_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"free_space_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.explain => /_pghero(/:database)/explain(.:format)
  // function(options)
  pg_hero_explain_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"explain",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_explain_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"explain",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.tune => /_pghero(/:database)/tune(.:format)
  // function(options)
  pg_hero_tune_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"tune",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_tune_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"tune",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.connections => /_pghero(/:database)/connections(.:format)
  // function(options)
  pg_hero_connections_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_connections_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.maintenance => /_pghero(/:database)/maintenance(.:format)
  // function(options)
  pg_hero_maintenance_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"maintenance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_maintenance_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"maintenance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.kill => /_pghero(/:database)/kill(.:format)
  // function(options)
  pg_hero_kill_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_kill_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.kill_long_running_queries => /_pghero(/:database)/kill_long_running_queries(.:format)
  // function(options)
  pg_hero_kill_long_running_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_long_running_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_kill_long_running_queries_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_long_running_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.kill_all => /_pghero(/:database)/kill_all(.:format)
  // function(options)
  pg_hero_kill_all_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_kill_all_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.enable_query_stats => /_pghero(/:database)/enable_query_stats(.:format)
  // function(options)
  pg_hero_enable_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"enable_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_enable_query_stats_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"enable_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.reset_query_stats => /_pghero(/:database)/reset_query_stats(.:format)
  // function(options)
  pg_hero_reset_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"reset_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_reset_query_stats_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"reset_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.system_stats => /_pghero(/:database)/system_stats(.:format)
  // function(options)
  pg_hero_system_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_system_stats_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.query_stats => /_pghero(/:database)/query_stats(.:format)
  // function(options)
  pg_hero_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
pg_hero_query_stats_url: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]], true),
// pg_hero.root => /_pghero/(:database)(.:format)
  // function(options)
  pg_hero_root_path: Utils.route([["database",false],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[2,[1,[3,"database",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
pg_hero_root_url: Utils.route([["database",false],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"_pghero",false]],[7,"/",false]],[2,[1,[3,"database",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// preview_modal_lines_user_bot_booking_page => /lines/user_bot/owner(/:business_owner_id)/booking_pages/:id/preview_modal(.:format)
  // function(id, options)
  preview_modal_lines_user_bot_booking_page_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
preview_modal_lines_user_bot_booking_page_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// profile => /profile(.:format)
  // function(options)
  profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
profile_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
rails_blob_representation_url: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
rails_blob_representation_proxy_url: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_conductor_inbound_email_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_conductor_inbound_email_incinerate => /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_incinerate_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"incinerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_conductor_inbound_email_incinerate_url: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"incinerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_conductor_inbound_email_reroute_url: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_conductor_inbound_email_sources_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_conductor_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
rails_direct_uploads_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_disk_service_url: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
rails_mailgun_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_mandrill_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_mandrill_inbound_health_check_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_postmark_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_relay_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
rails_sendgrid_inbound_emails_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
rails_service_blob_url: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
rails_service_blob_proxy_url: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// read_settings_dashboard_for_staff_warnings => /warnings/read_settings_dashboard_for_staff(.:format)
  // function(options)
  read_settings_dashboard_for_staff_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"read_settings_dashboard_for_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
read_settings_dashboard_for_staff_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"read_settings_dashboard_for_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// receipt_admin_withdrawal => /admin/withdrawals/:id/receipt(.:format)
  // function(id, options)
  receipt_admin_withdrawal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"withdrawals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
receipt_admin_withdrawal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"withdrawals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// receipt_lines_user_bot_settings_payment => /lines/user_bot/owner(/:business_owner_id)/settings/payments/:id/receipt(.:format)
  // function(id, options)
  receipt_lines_user_bot_settings_payment_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
receipt_lines_user_bot_settings_payment_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// receipt_settings_payment => /settings/payments/:id/receipt(.:format)
  // function(id, options)
  receipt_settings_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
receipt_settings_payment_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// recent_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/recent(.:format)
  // function(options)
  recent_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"recent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
recent_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"recent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// recent_user_customers => /users/:user_id/customers/recent(.:format)
  // function(user_id, options)
  recent_user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"recent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
recent_user_customers_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"recent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// referral => /referrals/:token(.:format)
  // function(token, options)
  referral_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"referrals",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
referral_url: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"referrals",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// refund_lines_user_bot_customer_payment => /lines/user_bot/owner(/:business_owner_id)/customer/payments/:id/refund(.:format)
  // function(id, options)
  refund_lines_user_bot_customer_payment_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
refund_lines_user_bot_customer_payment_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// refund_lines_user_bot_customer_reservations => /lines/user_bot/owner(/:business_owner_id)/customer/reservations/:reservation_id/refund/:customer_id(.:format)
  // function(reservation_id, customer_id, options)
  refund_lines_user_bot_customer_reservations_path: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"refund",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
refund_lines_user_bot_customer_reservations_url: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"refund",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// refund_lines_user_bot_settings_payments => /lines/user_bot/owner(/:business_owner_id)/settings/payments/refund(.:format)
  // function(options)
  refund_lines_user_bot_settings_payments_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
refund_lines_user_bot_settings_payments_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// refund_modal_lines_user_bot_customer_payment => /lines/user_bot/owner(/:business_owner_id)/customer/payments/:id/refund_modal(.:format)
  // function(id, options)
  refund_modal_lines_user_bot_customer_payment_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refund_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
refund_modal_lines_user_bot_customer_payment_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refund_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// refund_modal_lines_user_bot_customer_reservations => /lines/user_bot/owner(/:business_owner_id)/customer/reservations/:reservation_id/refund_modal/:customer_id(.:format)
  // function(reservation_id, customer_id, options)
  refund_modal_lines_user_bot_customer_reservations_path: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"refund_modal",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
refund_modal_lines_user_bot_customer_reservations_url: Utils.route([["business_owner_id",false],["reservation_id",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"reservation_id",false],[2,[7,"/",false],[2,[6,"refund_modal",false],[2,[7,"/",false],[2,[3,"customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]], true),
// refund_settings_payments => /settings/payments/refund(.:format)
  // function(options)
  refund_settings_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
refund_settings_payments_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"refund",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// reject_admin_business_application => /admin/business_applications/:id/reject(.:format)
  // function(id, options)
  reject_admin_business_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"business_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
reject_admin_business_application_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"business_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// reorder_lines_user_bot_service_chapters => /lines/user_bot/owner(/:business_owner_id)/services/:service_id/chapters/reorder(.:format)
  // function(service_id, options)
  reorder_lines_user_bot_service_chapters_path: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[6,"reorder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
reorder_lines_user_bot_service_chapters_url: Utils.route([["business_owner_id",false],["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"chapters",false],[2,[7,"/",false],[2,[6,"reorder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// reorder_menu_priority_lines_user_bot_booking_option => /lines/user_bot/owner(/:business_owner_id)/booking_options/:id/reorder_menu_priority(.:format)
  // function(id, options)
  reorder_menu_priority_lines_user_bot_booking_option_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reorder_menu_priority",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
reorder_menu_priority_lines_user_bot_booking_option_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reorder_menu_priority",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// repeating_dates_settings_user_menus => /settings/users/:user_id/menus/repeating_dates(.:format)
  // function(user_id, options)
  repeating_dates_settings_user_menus_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"repeating_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
repeating_dates_settings_user_menus_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[6,"repeating_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// reply_message_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/reply_message(.:format)
  // function(options)
  reply_message_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"reply_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
reply_message_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"reply_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// resend_activation_email_settings_user_staffs => /settings/users/:user_id/staffs/resend_activation_email(.:format)
  // function(user_id, options)
  resend_activation_email_settings_user_staffs_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"resend_activation_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
resend_activation_email_settings_user_staffs_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"resend_activation_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// resend_activation_sms_lines_user_bot_settings_staffs => /lines/user_bot/owner(/:business_owner_id)/settings/staffs/resend_activation_sms(.:format)
  // function(options)
  resend_activation_sms_lines_user_bot_settings_staffs_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"resend_activation_sms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
resend_activation_sms_lines_user_bot_settings_staffs_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"resend_activation_sms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// reservation_user_filter_index => /reservation/users/:user_id/filter(.:format)
  // function(user_id, options)
  reservation_user_filter_index_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reservation",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
reservation_user_filter_index_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reservation",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// reservation_user_saved_filters => /reservation/users/:user_id/saved_filters(.:format)
  // function(user_id, options)
  reservation_user_saved_filters_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reservation",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
reservation_user_saved_filters_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reservation",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"saved_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// reset_lines_user_bot_settings_social_account => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/reset(.:format)
  // function(options)
  reset_lines_user_bot_settings_social_account_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
reset_lines_user_bot_settings_social_account_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
root_url: Utils.route([], {}, [7,"/",false], true),
// sale_page => /sale_pages/:slug(.:format)
  // function(slug, options)
  sale_page_path: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sale_pages",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
sale_page_url: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sale_pages",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// save_changes_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/save_changes/:reservation_customer_id(.:format)
  // function(reservation_customer_id, options)
  save_changes_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["reservation_customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save_changes",false],[2,[7,"/",false],[2,[3,"reservation_customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
save_changes_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["reservation_customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save_changes",false],[2,[7,"/",false],[2,[3,"reservation_customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// save_changes_user_customers => /users/:user_id/customers/save_changes/:reservation_customer_id(.:format)
  // function(user_id, reservation_customer_id, options)
  save_changes_user_customers_path: Utils.route([["user_id",true],["reservation_customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save_changes",false],[2,[7,"/",false],[2,[3,"reservation_customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
save_changes_user_customers_url: Utils.route([["user_id",true],["reservation_customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save_changes",false],[2,[7,"/",false],[2,[3,"reservation_customer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// save_draft_message_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/save_draft_message(.:format)
  // function(options)
  save_draft_message_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save_draft_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
save_draft_message_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save_draft_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// save_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/save(.:format)
  // function(options)
  save_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
save_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// save_user_customers => /users/:user_id/customers/save(.:format)
  // function(user_id, options)
  save_user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
save_user_customers_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"save",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// scenario_admin_custom_messages => /admin/custom_messages/scenario/:scenario(.:format)
  // function(scenario, options)
  scenario_admin_custom_messages_path: Utils.route([["scenario",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
scenario_admin_custom_messages_url: Utils.route([["scenario",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// scenarios_admin_custom_messages => /admin/custom_messages/scenarios(.:format)
  // function(options)
  scenarios_admin_custom_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenarios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
scenarios_admin_custom_messages_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenarios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// schedule_lines_user_bot_shop_reservations => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/schedule(.:format)
  // function(shop_id, options)
  schedule_lines_user_bot_shop_reservations_path: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
schedule_lines_user_bot_shop_reservations_url: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// search_episodes_online_service => /online_services/:slug/search/:keyword(.:format)
  // function(slug, keyword, options)
  search_episodes_online_service_path: Utils.route([["slug",true],["keyword",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[3,"keyword",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
search_episodes_online_service_url: Utils.route([["slug",true],["keyword",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[3,"keyword",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// search_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/search(.:format)
  // function(options)
  search_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
search_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// search_user_customers => /users/:user_id/customers/search(.:format)
  // function(user_id, options)
  search_user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
search_user_customers_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_booking_tour => /settings/booking_tour(.:format)
  // function(options)
  settings_booking_tour_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"booking_tour",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_booking_tour_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"booking_tour",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_dashboard => /settings/dashboard(.:format)
  // function(options)
  settings_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_dashboard_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_end_tour => /settings/end_tour(.:format)
  // function(options)
  settings_end_tour_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"end_tour",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_end_tour_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"end_tour",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_hide_tour_warning => /settings/hide_tour_warning(.:format)
  // function(options)
  settings_hide_tour_warning_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"hide_tour_warning",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_hide_tour_warning_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"hide_tour_warning",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_payments => /settings/payments(.:format)
  // function(options)
  settings_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_payments_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_plans => /settings/plans(.:format)
  // function(options)
  settings_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_plans_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_profile => /settings/profile(.:format)
  // function(options)
  settings_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_profile_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_referrers => /settings/referrers(.:format)
  // function(options)
  settings_referrers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"referrers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_referrers_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"referrers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_tour => /settings/tour(.:format)
  // function(options)
  settings_tour_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tour",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_tour_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tour",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// settings_tours_business_schedule => /settings/tours/business_schedule(.:format)
  // function(options)
  settings_tours_business_schedule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"business_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
settings_tours_business_schedule_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"business_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// settings_tours_contact_group => /settings/tours/contact_group(.:format)
  // function(options)
  settings_tours_contact_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"contact_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
settings_tours_contact_group_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"contact_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// settings_tours_current_step_warning => /settings/tours/current_step_warning(.:format)
  // function(options)
  settings_tours_current_step_warning_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"current_step_warning",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
settings_tours_current_step_warning_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"current_step_warning",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// settings_tours_menu => /settings/tours/menu(.:format)
  // function(options)
  settings_tours_menu_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"menu",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
settings_tours_menu_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"menu",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// settings_tours_reservation_setting => /settings/tours/reservation_setting(.:format)
  // function(options)
  settings_tours_reservation_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"reservation_setting",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
settings_tours_reservation_setting_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"reservation_setting",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// settings_tours_shop => /settings/tours/shop(.:format)
  // function(options)
  settings_tours_shop_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"shop",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
settings_tours_shop_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"shop",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// settings_tours_working_time => /settings/tours/working_time(.:format)
  // function(options)
  settings_tours_working_time_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"working_time",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
settings_tours_working_time_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"tours",false],[2,[7,"/",false],[2,[6,"working_time",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// settings_user_booking_option => /settings/users/:user_id/booking_options/:id(.:format)
  // function(user_id, id, options)
  settings_user_booking_option_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_booking_option_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_booking_options => /settings/users/:user_id/booking_options(.:format)
  // function(user_id, options)
  settings_user_booking_options_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_booking_options_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_booking_page => /settings/users/:user_id/booking_pages/:id(.:format)
  // function(user_id, id, options)
  settings_user_booking_page_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_booking_page_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_booking_pages => /settings/users/:user_id/booking_pages(.:format)
  // function(user_id, options)
  settings_user_booking_pages_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_booking_pages_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_business_schedules => /settings/users/:user_id/business_schedules(.:format)
  // function(user_id, options)
  settings_user_business_schedules_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_business_schedules_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_categories => /settings/users/:user_id/categories(.:format)
  // function(user_id, options)
  settings_user_categories_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_categories_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_category => /settings/users/:user_id/categories/:id(.:format)
  // function(user_id, id, options)
  settings_user_category_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_category_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_contact_group => /settings/users/:user_id/contact_groups/:id(.:format)
  // function(user_id, id, options)
  settings_user_contact_group_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_contact_group_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_contact_groups => /settings/users/:user_id/contact_groups(.:format)
  // function(user_id, options)
  settings_user_contact_groups_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_contact_groups_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_dashboard => /settings/users/:user_id/dashboard(.:format)
  // function(user_id, options)
  settings_user_dashboard_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_dashboard_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_menu => /settings/users/:user_id/menus/:id(.:format)
  // function(user_id, id, options)
  settings_user_menu_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_menu_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_menus => /settings/users/:user_id/menus(.:format)
  // function(user_id, options)
  settings_user_menus_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_menus_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"menus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_rank => /settings/users/:user_id/ranks/:id(.:format)
  // function(user_id, id, options)
  settings_user_rank_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"ranks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_rank_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"ranks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_ranks => /settings/users/:user_id/ranks(.:format)
  // function(user_id, options)
  settings_user_ranks_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"ranks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_ranks_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"ranks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_reservation_setting => /settings/users/:user_id/reservation_settings/:id(.:format)
  // function(user_id, id, options)
  settings_user_reservation_setting_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservation_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_reservation_setting_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservation_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_reservation_settings => /settings/users/:user_id/reservation_settings(.:format)
  // function(user_id, options)
  settings_user_reservation_settings_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservation_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_reservation_settings_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reservation_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_shop => /settings/users/:user_id/shops/:id(.:format)
  // function(user_id, id, options)
  settings_user_shop_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_shop_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_shops => /settings/users/:user_id/shops(.:format)
  // function(user_id, options)
  settings_user_shops_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_shops_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_social_account => /settings/users/:user_id/social_accounts/:id(.:format)
  // function(user_id, id, options)
  settings_user_social_account_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"social_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_social_account_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"social_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_social_accounts => /settings/users/:user_id/social_accounts(.:format)
  // function(user_id, options)
  settings_user_social_accounts_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"social_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_social_accounts_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"social_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_staff => /settings/users/:user_id/staffs/:id(.:format)
  // function(user_id, id, options)
  settings_user_staff_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_staff_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_user_staffs => /settings/users/:user_id/staffs(.:format)
  // function(user_id, options)
  settings_user_staffs_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
settings_user_staffs_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// settings_user_working_time_staff => /settings/users/:user_id/working_time/staffs/:id(.:format)
  // function(user_id, id, options)
  settings_user_working_time_staff_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"working_time",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
settings_user_working_time_staff_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"working_time",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// settings_user_working_time_staffs => /settings/users/:user_id/working_time/staffs(.:format)
  // function(user_id, options)
  settings_user_working_time_staffs_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"working_time",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
settings_user_working_time_staffs_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"working_time",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// settings_withdrawal => /settings/withdrawals/:id(.:format)
  // function(id, options)
  settings_withdrawal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"withdrawals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
settings_withdrawal_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"withdrawals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// settings_withdrawals => /settings/withdrawals(.:format)
  // function(options)
  settings_withdrawals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"withdrawals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
settings_withdrawals_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"withdrawals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// shop => /shops/:id(.:format)
  // function(id, options)
  shop_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
shop_url: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// shop_dashboard_for_admin_warnings => /warnings/shop_dashboard_for_admin(.:format)
  // function(options)
  shop_dashboard_for_admin_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"shop_dashboard_for_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
shop_dashboard_for_admin_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"shop_dashboard_for_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// shop_dashboard_for_staff_warnings => /warnings/shop_dashboard_for_staff(.:format)
  // function(options)
  shop_dashboard_for_staff_warnings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"shop_dashboard_for_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
shop_dashboard_for_staff_warnings_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"shop_dashboard_for_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// shop_reservation => /shops/:shop_id/reservations/:id(.:format)
  // function(shop_id, id, options)
  shop_reservation_path: Utils.route([["shop_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
shop_reservation_url: Utils.route([["shop_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// shop_reservations => /shops/:shop_id/reservations(.:format)
  // function(shop_id, options)
  shop_reservations_path: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
shop_reservations_url: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// shops_lines_user_bot_settings_business_schedules => /lines/user_bot/owner(/:business_owner_id)/settings/business_schedules/shops(.:format)
  // function(options)
  shops_lines_user_bot_settings_business_schedules_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"shops",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
shops_lines_user_bot_settings_business_schedules_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"shops",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// staffs_shop_reservations_available_options => /shops/:shop_id/reservations/available_options/staffs(.:format)
  // function(shop_id, options)
  staffs_shop_reservations_available_options_path: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"available_options",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
staffs_shop_reservations_available_options_url: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"available_options",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// sync_settings_user_contact_group => /settings/users/:user_id/contact_groups/:id/sync(.:format)
  // function(user_id, id, options)
  sync_settings_user_contact_group_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
sync_settings_user_contact_group_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]], true),
// tagged_episodes_online_service => /online_services/:slug/episodes(/:tag)(.:format)
  // function(slug, options)
  tagged_episodes_online_service_path: Utils.route([["slug",true],["tag",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[1,[2,[7,"/",false],[3,"tag",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
tagged_episodes_online_service_url: Utils.route([["slug",true],["tag",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[1,[2,[7,"/",false],[3,"tag",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]], true),
// times_shop_reservations_available_options => /shops/:shop_id/reservations/available_options/times(.:format)
  // function(shop_id, options)
  times_shop_reservations_available_options_path: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"available_options",false],[2,[7,"/",false],[2,[6,"times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
times_shop_reservations_available_options_url: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"available_options",false],[2,[7,"/",false],[2,[6,"times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// toggle_reminder_permission_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/toggle_reminder_permission(.:format)
  // function(options)
  toggle_reminder_permission_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"toggle_reminder_permission",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
toggle_reminder_permission_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"toggle_reminder_permission",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// toggle_reminder_permission_user_customers => /users/:user_id/customers/toggle_reminder_permission(.:format)
  // function(user_id, options)
  toggle_reminder_permission_user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"toggle_reminder_permission",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
toggle_reminder_permission_user_customers_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"toggle_reminder_permission",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// trial_end_lines_user_bot_warnings => /lines/user_bot/owner(/:business_owner_id)/warnings/trial_end(.:format)
  // function(options)
  trial_end_lines_user_bot_warnings_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"trial_end",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
trial_end_lines_user_bot_warnings_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"warnings",false],[2,[7,"/",false],[2,[6,"trial_end",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// unread_message_lines_user_bot_customers => /lines/user_bot/owner(/:business_owner_id)/customers/unread_message(.:format)
  // function(options)
  unread_message_lines_user_bot_customers_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"unread_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
unread_message_lines_user_bot_customers_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"unread_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]], true),
// update_admin_custom_messages => /admin/custom_messages/scenario/:scenario/:id(.:format)
  // function(scenario, id, options)
  update_admin_custom_messages_path: Utils.route([["scenario",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
update_admin_custom_messages_url: Utils.route([["scenario",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"custom_messages",false],[2,[7,"/",false],[2,[6,"scenario",false],[2,[7,"/",false],[2,[3,"scenario",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// update_booking_options_order_lines_user_bot_booking_page => /lines/user_bot/owner(/:business_owner_id)/booking_pages/:id/update_booking_options_order(.:format)
  // function(id, options)
  update_booking_options_order_lines_user_bot_booking_page_path: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_booking_options_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
update_booking_options_order_lines_user_bot_booking_page_url: Utils.route([["business_owner_id",false],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_booking_options_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// update_lines_user_bot_settings_business_schedules => /lines/user_bot/owner(/:business_owner_id)/settings/business_schedules/shop/:shop_id/update/:wday(.:format)
  // function(shop_id, wday, options)
  update_lines_user_bot_settings_business_schedules_path: Utils.route([["business_owner_id",false],["shop_id",true],["wday",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"shop",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"wday",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]),
update_lines_user_bot_settings_business_schedules_url: Utils.route([["business_owner_id",false],["shop_id",true],["wday",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"shop",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"wday",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]], true),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
update_rails_disk_service_url: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// update_settings_user_shop_business_schedules => /settings/users/:user_id/shops/:shop_id/business_schedules/update(.:format)
  // function(user_id, shop_id, options)
  update_settings_user_shop_business_schedules_path: Utils.route([["user_id",true],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
update_settings_user_shop_business_schedules_url: Utils.route([["user_id",true],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"business_schedules",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true),
// upsert_booking_options_lines_user_bot_settings_line_keyword => /lines/user_bot/owner(/:business_owner_id)/settings/line_keyword/upsert_booking_options(.:format)
  // function(options)
  upsert_booking_options_lines_user_bot_settings_line_keyword_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"line_keyword",false],[2,[7,"/",false],[2,[6,"upsert_booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
upsert_booking_options_lines_user_bot_settings_line_keyword_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"line_keyword",false],[2,[7,"/",false],[2,[6,"upsert_booking_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// upsert_booking_pages_lines_user_bot_settings_line_keyword => /lines/user_bot/owner(/:business_owner_id)/settings/line_keyword/upsert_booking_pages(.:format)
  // function(options)
  upsert_booking_pages_lines_user_bot_settings_line_keyword_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"line_keyword",false],[2,[7,"/",false],[2,[6,"upsert_booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
upsert_booking_pages_lines_user_bot_settings_line_keyword_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"line_keyword",false],[2,[7,"/",false],[2,[6,"upsert_booking_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// upsert_lines_user_bot_settings_social_account_social_rich_menus => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/social_rich_menus/upsert(.:format)
  // function(options)
  upsert_lines_user_bot_settings_social_account_social_rich_menus_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[6,"upsert",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
upsert_lines_user_bot_settings_social_account_social_rich_menus_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"social_rich_menus",false],[2,[7,"/",false],[2,[6,"upsert",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// user_chats => /users/:user_id/chats(.:format)
  // function(user_id, options)
  user_chats_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"chats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
user_chats_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"chats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_confirmation_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// user_customers => /users/:user_id/customers(.:format)
  // function(user_id, options)
  user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
user_customers_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// user_from_callbacks_staff_accounts => /callbacks/staff_accounts/:token(.:format)
  // function(token, options)
  user_from_callbacks_staff_accounts_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"staff_accounts",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
user_from_callbacks_staff_accounts_url: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"staff_accounts",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// user_google_oauth2_omniauth_authorize => /users/auth/google_oauth2(.:format)
  // function(options)
  user_google_oauth2_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
user_google_oauth2_omniauth_authorize_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// user_google_oauth2_omniauth_callback => /users/auth/google_oauth2/callback(.:format)
  // function(options)
  user_google_oauth2_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
user_google_oauth2_omniauth_callback_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// user_line_omniauth_authorize => /users/auth/line(.:format)
  // function(options)
  user_line_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"line",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
user_line_omniauth_authorize_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"line",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// user_line_omniauth_callback => /users/auth/line/callback(.:format)
  // function(options)
  user_line_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"line",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
user_line_omniauth_callback_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"line",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// user_login => /userlogin(.:format)
  // function(options)
  user_login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"userlogin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
user_login_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"userlogin",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_password_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
user_registration_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_session_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// user_square_omniauth_authorize => /users/auth/square(.:format)
  // function(options)
  user_square_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"square",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
user_square_omniauth_authorize_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"square",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// user_square_omniauth_callback => /users/auth/square/callback(.:format)
  // function(options)
  user_square_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"square",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
user_square_omniauth_callback_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"square",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// user_stripe_connect_omniauth_authorize => /users/auth/stripe_connect(.:format)
  // function(options)
  user_stripe_connect_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"stripe_connect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
user_stripe_connect_omniauth_authorize_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"stripe_connect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// user_stripe_connect_omniauth_callback => /users/auth/stripe_connect/callback(.:format)
  // function(options)
  user_stripe_connect_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"stripe_connect",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
user_stripe_connect_omniauth_callback_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"stripe_connect",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// user_unlock => /users/unlock(.:format)
  // function(options)
  user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
user_unlock_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// validate_lines_user_bot_shop_reservations => /lines/user_bot/owner(/:business_owner_id)/shops/:shop_id/reservations/validate(.:format)
  // function(shop_id, options)
  validate_lines_user_bot_shop_reservations_path: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
validate_lines_user_bot_shop_reservations_url: Utils.route([["business_owner_id",false],["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]], true),
// validate_shop_reservations => /shops/:shop_id/reservations/validate(.:format)
  // function(shop_id, options)
  validate_shop_reservations_path: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
validate_shop_reservations_url: Utils.route([["shop_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shops",false],[2,[7,"/",false],[2,[3,"shop_id",false],[2,[7,"/",false],[2,[6,"reservations",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// validate_special_dates_settings_user_booking_pages => /settings/users/:user_id/booking_pages/validate_special_dates(.:format)
  // function(user_id, options)
  validate_special_dates_settings_user_booking_pages_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"validate_special_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
validate_special_dates_settings_user_booking_pages_url: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"booking_pages",false],[2,[7,"/",false],[2,[6,"validate_special_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]], true),
// watch_episode_online_service => /online_services/:slug/episodes/:episode_id(.:format)
  // function(slug, episode_id, options)
  watch_episode_online_service_path: Utils.route([["slug",true],["episode_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"episode_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
watch_episode_online_service_url: Utils.route([["slug",true],["episode_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"episodes",false],[2,[7,"/",false],[2,[3,"episode_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// watch_lesson_online_service => /online_services/:slug/lessons/:lesson_id(.:format)
  // function(slug, lesson_id, options)
  watch_lesson_online_service_path: Utils.route([["slug",true],["lesson_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"lesson_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
watch_lesson_online_service_url: Utils.route([["slug",true],["lesson_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"online_services",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"lesson_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]], true),
// web_push_subscriptions => /web_push_subscriptions(.:format)
  // function(options)
  web_push_subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"web_push_subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
web_push_subscriptions_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"web_push_subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]], true),
// webhook_modal_lines_user_bot_settings_social_account => /lines/user_bot/owner(/:business_owner_id)/settings/social_account/webhook_modal(.:format)
  // function(options)
  webhook_modal_lines_user_bot_settings_social_account_path: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"webhook_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
webhook_modal_lines_user_bot_settings_social_account_url: Utils.route([["business_owner_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"lines",false],[2,[7,"/",false],[2,[6,"user_bot",false],[2,[7,"/",false],[2,[6,"owner",false],[2,[1,[2,[7,"/",false],[3,"business_owner_id",false]],false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"social_account",false],[2,[7,"/",false],[2,[6,"webhook_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]], true),
// webhooks_line => /webhooks/line/:channel_id(.:format)
  // function(channel_id, options)
  webhooks_line_path: Utils.route([["channel_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"line",false],[2,[7,"/",false],[2,[3,"channel_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
webhooks_line_url: Utils.route([["channel_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"line",false],[2,[7,"/",false],[2,[3,"channel_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]], true),
// webhooks_stripe => /webhooks/stripe(.:format)
  // function(options)
  webhooks_stripe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
webhooks_stripe_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// webhooks_user_bot_line => /webhooks/user_bot_line(.:format)
  // function(options)
  webhooks_user_bot_line_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"user_bot_line",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
webhooks_user_bot_line_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"user_bot_line",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// working_schedule_calendars => /calendars/working_schedule(.:format)
  // function(options)
  working_schedule_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"working_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
working_schedule_calendars_url: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"working_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]], true),
// working_schedules_settings_user_working_time_staff => /settings/users/:user_id/working_time/staffs/:id/working_schedules(.:format)
  // function(user_id, id, options)
  working_schedules_settings_user_working_time_staff_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"working_time",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"working_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
working_schedules_settings_user_working_time_staff_url: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"working_time",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"working_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]], true)}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);

